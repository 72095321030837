import { Box, Button, Flex, Text, VStack } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

import { GridCards } from "../../../components/GridCards";
import { PageLayout } from "../../../components/PageLayout";
import { authSelector } from "../../auth/data/authSlice";
import { useGetPortfolioDetailsQuery } from "../api/projectsApi";
import { PortfolioImage } from "../components/PortfolioImage";
import { ProjectCard } from "../components/ProjectCard";

export const PortfolioDetails = () => {
    const { portfolioId = "" } = useParams<{ portfolioId: string }>();
    const { userId } = useSelector(authSelector);

    const { data, isLoading } = useGetPortfolioDetailsQuery({ id: portfolioId });

    if (isLoading) return <div>Loading...</div>;

    if (!data) return <div>Portfolio not found</div>;

    const { projects, name, description, creator, id } = data;

    const hasProjects = projects.length > 0;
    const isOwner = creator === userId;

    return (
        <PageLayout
            title={name}
            rightAction={
                isOwner && (
                    <Button size='sm' as={Link} to={"/project/add/" + id}>
                        Add project
                    </Button>
                )
            }>
            <VStack alignItems='flex-start' spacing='1rem' pb='1rem'>
                <Flex justifyContent='flex-start' gap='1rem' bg='white' w='full' borderRadius='lg' p='1rem' direction={{ base: "column", md: "row" }}>
                    <Box w={{ base: "100%", md: "20rem" }}>
                        <PortfolioImage images={data.energy_images} size='M' />
                    </Box>
                    <Text whiteSpace='pre-wrap' overflowY='auto' maxH='12rem' w={{ base: "100%", md: "70%" }}>
                        {description}
                    </Text>
                </Flex>
                <Box bg='white' w='full' p='1rem' borderRadius='lg' overflowY='auto'>
                    {hasProjects && (
                        <Text fontWeight='bold' fontSize='lg' mb='.5rem'>
                            Projects
                        </Text>
                    )}
                    {hasProjects ? (
                        <GridCards>
                            {projects.map(project => (
                                <ProjectCard key={project.id} project={project} />
                            ))}
                        </GridCards>
                    ) : (
                        <Text fontWeight='bold'>No projects in this portfolio</Text>
                    )}
                </Box>
            </VStack>
        </PageLayout>
    );
};
