import { MultiSelect, Option, SelectionVisibilityMode } from "chakra-multiselect";
import { useState } from "react";

import { useGetProjectsStatusChoicesQuery } from "../modules/projects/api/projectsApi";

export const useStatusSelect = (defaultValue?: number, placeholder?: string) => {
    const { data } = useGetProjectsStatusChoicesQuery();
    const [value, setValue] = useState<string | undefined>(defaultValue ? String(defaultValue) : "");

    const options = data?.results.map(({ id, name }) => ({ label: name, value: String(id) }));

    const ProjectStatusSelect = data && (
        <MultiSelect
            placeholder={placeholder || "Select stage"}
            options={options || []}
            value={value}
            onChange={val => {
                //@ts-ignore
                setValue(val);
            }}
            size='md'
            single
            filterFn={(option, query) => {
                const searchValue = query as string;
                return option.filter(o => o.label.toLowerCase().includes(searchValue.toLowerCase()));
            }}
        />
    );
    return { ProjectStatusSelect, value: Number(value) };
};

export const useMultiStatusSelect = (defaultValue?: Option[], placeholder?: string) => {
    const { data } = useGetProjectsStatusChoicesQuery();
    const [value, setValue] = useState<Option[]>(defaultValue || []);

    const options: Option[] = data?.results.map(({ id, name }) => ({ label: name, value: String(id) })) || [];

    const ProjectStatusSelect = (
        <MultiSelect
            _placeholder={{ color: "gray.400" }}
            placeholder={placeholder || "Select countries"}
            options={options}
            value={value}
            onChange={val => {
                // @ts-ignore
                setValue(val);
            }}
            size='md'
            single={false}
            selectionVisibleIn={SelectionVisibilityMode.Both}
            filterFn={(option, query) => {
                const searchValue = query as string;
                return option.filter(o => o.label.toLowerCase().includes(searchValue.toLowerCase()));
            }}
        />
    );

    return { ProjectStatusSelect, values: value.map(({ value }) => Number(value)) };
};
