import { IconType } from "../icons";

export const WindIcon = ({ size = 24, color = "#03C988" }: IconType) => (
    <svg width={size} height={size} fill='none'>
        <g stroke={color} strokeLinecap='round' strokeLinejoin='round' strokeWidth={1.5} clipPath='url(#a)'>
            <path d='M12 18c.318.858 1.282 1.5 2.25 1.5a2.25 2.25 0 0 0 0-4.5H3.75M9 6c.318-.858 1.282-1.5 2.25-1.5a2.25 2.25 0 0 1 0 4.5h-9M17.25 9c.318-.858 1.282-1.5 2.25-1.5a2.25 2.25 0 0 1 0 4.5H3' />
        </g>
        <defs>
            <clipPath id='a'>
                <path fill='#fff' d='M0 0h24v24H0z' />
            </clipPath>
        </defs>
    </svg>
);
