import { api } from "../../../api/api";
import { ChartData } from "./types";

const VALUE_TAG = "Value";
const PERFORMANCE_TAG = "Performance";
const RECS_TAG = "Recs";
const ASSETS_TAG = "AssetsAllocations";
const REALIZED_TAG = "Realized";
const GEO_TAG = "Geographical";

export const portfolioApi = api
    .enhanceEndpoints({
        addTagTypes: [VALUE_TAG, PERFORMANCE_TAG, RECS_TAG, ASSETS_TAG, REALIZED_TAG, GEO_TAG]
    })
    .injectEndpoints({
        endpoints: build => ({
            getPortfolioValue: build.query<{ value: number }, void>({
                query: () => ({
                    method: "GET",
                    url: "portfolio/value/"
                }),
                providesTags: [VALUE_TAG]
            }),
            getPerformanceOverview: build.query<ChartData<"line">, void>({
                query: () => ({
                    method: "GET",
                    url: "portfolio/performance/"
                }),
                providesTags: [PERFORMANCE_TAG]
            }),
            getRecs: build.query<ChartData<"bar">, void>({
                query: () => ({
                    method: "GET",
                    url: "portfolio/recs/"
                }),
                providesTags: [RECS_TAG]
            }),
            getRealizedValue: build.query<ChartData<"bar">, void>({
                query: () => ({
                    method: "GET",
                    url: "portfolio/realized/"
                }),
                providesTags: [REALIZED_TAG]
            }),
            getAssetsAllocations: build.query<ChartData<"doughnut">, void>({
                query: () => ({
                    method: "GET",
                    url: "portfolio/assets/"
                }),
                providesTags: [ASSETS_TAG]
            }),
            getNetCashflow: build.query<ChartData<"bar">, void>({
                query: () => ({
                    method: "GET",
                    url: "portfolio/cashflow/"
                }),
                providesTags: [ASSETS_TAG]
            }),
            getGeographical: build.query<{ labels: string[]; data: number[] }, void>({
                query: () => ({
                    method: "GET",
                    url: "portfolio/geographical/"
                }),
                providesTags: [GEO_TAG]
            })
        })
    });

export const {
    useGetPerformanceOverviewQuery,
    useGetRecsQuery,
    useGetPortfolioValueQuery,
    useGetRealizedValueQuery,
    useGetAssetsAllocationsQuery,
    useGetNetCashflowQuery,
    useGetGeographicalQuery
} = portfolioApi;
