import { CheckIcon } from "@chakra-ui/icons";
import { Box, Circle, HStack, Text, VStack } from "@chakra-ui/react";

type FormStepsProps = {
    steps: string[];
    currentIndex: number;
    setCurrentIndex: (index: number) => void;
};

export const FormSteps = ({ steps, currentIndex, setCurrentIndex }: FormStepsProps) => {
    return (
        <HStack position='relative' display='flex' alignItems='center' justifyContent='space-between' spacing='3rem' mt='2rem' mb='4rem' mx='3rem'>
            <Box position='absolute' h='2px' left='0' right='0' bg='gray.200' />
            {steps.map((step, index) => (
                <StepCircle
                    key={step}
                    step={step}
                    isActive={currentIndex === index}
                    onPress={() => setCurrentIndex(index)}
                    isDone={currentIndex > index}
                />
            ))}
        </HStack>
    );
};

type StepCircleProps = {
    step: string;
    isActive: boolean;
    isDone: boolean;
    onPress: () => void;
};

const StepCircle = ({ step, isActive, isDone, onPress }: StepCircleProps) => {
    const isEnabled = isActive || isDone;
    return (
        <VStack position='relative' onClick={isEnabled ? onPress : undefined} cursor={isEnabled ? "pointer" : "unset"}>
            <Circle p='2' bg={isEnabled ? "green.400" : "gray.200"} size='1rem' zIndex='1' transition='.5s'>
                {isDone && <CheckIcon boxSize='.6rem' />}
            </Circle>
            <Text fontSize='xs' fontWeight='bold' position='absolute' bottom='-5' color={isEnabled ? "black" : "gray.400"}>
                {step}
            </Text>
        </VStack>
    );
};
