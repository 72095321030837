import { Box } from "@chakra-ui/react";

type HorizontalBarProps = {
    value: string;
    min?: string;
    max?: string;
};

export const HorizontalBar = ({ value, min, max }: HorizontalBarProps) => {
    return (
        <Box display='flex' alignItems='center' w='full' gap='.8rem'>
            {min && <Box fontSize='sm'>{min}</Box>}
            <Box w='full' h='2' rounded='md' bg='gray.200' position='relative'>
                <Box rounded='md' position='absolute' top='0' left='0' bottom='0' w={value} bg='green.500'></Box>
            </Box>
            {max && <Box fontSize='sm'>{max}</Box>}
        </Box>
    );
};
