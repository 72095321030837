import { IconType } from ".";

export const BiohazardIcon = ({ size = 24, color = "black" }: IconType) => (
    <svg width={size} height={size + 1} fill='none'>
        <g stroke={color} strokeLinecap='round' strokeLinejoin='round' strokeWidth={1.5} clipPath='url(#a)'>
            <path d='M12 17.825a5.25 5.25 0 1 0 0-10.5 5.25 5.25 0 0 0 0 10.5Z' />
            <path d='M19.5 19.684a4.875 4.875 0 1 1 2.25-4.11' />
            <path d='M2.25 15.575a4.875 4.875 0 1 1 2.25 4.109' />
            <path d='M14.25 2.805a4.875 4.875 0 1 1-4.5 0' />
        </g>
        <defs>
            <clipPath id='a'>
                <path fill='#fff' d='M0 .2h24v24H0z' />
            </clipPath>
        </defs>
    </svg>
);
