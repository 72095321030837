import { Button, FormControl, FormErrorMessage, FormLabel, Input, VStack } from "@chakra-ui/react";

import { ClearErrorsType, ErrorsType, RegisterType, SetErrorType, SetValueType } from "../../pages/ProjectCreate";

type InvestmentProps = {
    setCurrentIndex: (index: number) => void;
    setValue: SetValueType;
    setError: SetErrorType;
    errors: ErrorsType;
    clearErrors: ClearErrorsType;
    min_investment: number;
    target_amount: number;
    yield_to_maturity: number;
    deadline: number;
    register: RegisterType;
};

export const Investment = ({
    setCurrentIndex,
    setValue,
    setError,
    errors,
    clearErrors,
    min_investment,
    target_amount,
    yield_to_maturity,
    deadline,
    register
}: InvestmentProps) => {
    const onValidate = () => {
        clearErrors();
        if (!target_amount) {
            setError("target_amount", { message: "Target amount is required" });
            return;
        }
        if (!min_investment) {
            setError("min_investment", { message: "Minimum investment is required" });
            return;
        }
        if (!yield_to_maturity) {
            setError("yield_to_maturity", { message: "Yield is required" });
            return;
        }
        if (!deadline) {
            setError("deadline_years", { message: "Deadline is required" });
            return;
        }
        setCurrentIndex(4);
    };
    return (
        <VStack spacing='1rem'>
            <FormControl isInvalid={Boolean(errors.target_amount)}>
                <FormLabel htmlFor='target_amount'>Target amount</FormLabel>
                <Input
                    placeholder='Enter the target amount for the investment (e.g., $10 million)'
                    type='number'
                    {...register("target_amount", {
                        required: "This field is required",
                        minLength: {
                            value: 1,
                            message: "Minimum length should be 1"
                        }
                    })}
                />
                <FormErrorMessage>{errors.target_amount && errors.target_amount.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={Boolean(errors.min_investment)}>
                <FormLabel htmlFor='min_investment'>Minimum investment</FormLabel>
                <Input
                    type='number'
                    placeholder='If applicable, specify the minimum investment required (e.g., $100,000)'
                    {...register("min_investment", {
                        required: "This field is required",
                        minLength: {
                            value: 1,
                            message: "Minimum length should be 1"
                        }
                    })}
                />
                <FormErrorMessage>{errors.min_investment && errors.min_investment.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={Boolean(errors.yield_to_maturity)}>
                <FormLabel htmlFor='yield_to_maturity'>Yield</FormLabel>
                <Input
                    type='number'
                    placeholder='Enter the expected ARR (e.g., 8%)'
                    {...register("yield_to_maturity", {
                        required: "This field is required",
                        minLength: {
                            value: 1,
                            message: "Minimum length should be 1"
                        }
                    })}
                />
                <FormErrorMessage>{errors.yield_to_maturity && errors.yield_to_maturity.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={Boolean(errors.deadline_years)}>
                <FormLabel htmlFor='deadline'>Development maturity (in years)</FormLabel>
                <Input
                    id='deadline'
                    placeholder='Indicate the development maturity period (e.g., 2 years)'
                    size='md'
                    type='number'
                    value={deadline}
                    onChange={e => setValue("deadline_years", Number(e.target.value))}
                />
                <FormErrorMessage>{errors.deadline_years && errors.deadline_years.message}</FormErrorMessage>
            </FormControl>
            <Button type='submit' onClick={onValidate} w='full' mt='2rem'>
                Next
            </Button>
        </VStack>
    );
};
