import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";

import { PageLayout } from "../../../components/PageLayout";
import { NBOList } from "../components/NBOList";
import { Performance } from "../components/Performance";

export const Portfolio = () => {
    return (
        <PageLayout title='Portfolio'>
            <Tabs variant='soft-rounded' colorScheme='green' size='sm'>
                <TabList position='sticky' top='0' bg='gray.100'>
                    <Tab>Performance</Tab>
                    <Tab>NBO's</Tab>
                </TabList>
                <TabPanels>
                    <TabPanel px='0'>
                        <Performance />
                    </TabPanel>
                    <TabPanel px='0'>
                        <NBOList />
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </PageLayout>
    );
};
