import { HStack, Image, Text, VStack } from "@chakra-ui/react";
import { Link } from "react-router-dom";

import { getCountryFlag } from "../../../utils/flags";
import { NBOType } from "../../projects/api/types";

export const NBOCard = ({ nbo }: { nbo: NBOType }) => {
    const { project, amount } = nbo;
    return (
        <Link to={"/projects/" + project.slug} style={{ width: "100%" }}>
            <VStack borderWidth='1px' borderColor='gray.200' p='2' boxShadow='lg' borderRadius='md' alignItems='stretch'>
                <HStack>
                    <Text fontSize='lg' fontWeight='bold'>
                        {project.name}
                    </Text>
                    <Image w='1.2rem' src={getCountryFlag(project.location.country.code)} alt={project.energy_type.name} borderRadius='lg' />
                </HStack>
                <VStack alignItems='flex-start' spacing='0'>
                    <Text fontSize='xs'>Amount</Text>
                    <Text fontWeight='bold'>
                        {amount.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD"
                        })}
                    </Text>
                </VStack>
            </VStack>
        </Link>
    );
};
