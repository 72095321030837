import { createApi } from "@reduxjs/toolkit/query/react";

import { baseQueryWithReAuth } from "./baseQuery";

export const api = createApi({
    reducerPath: "api",
    baseQuery: baseQueryWithReAuth,
    tagTypes: [],
    endpoints: () => ({})
});
