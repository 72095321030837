import { useToast } from "@chakra-ui/react";
import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { api } from "../../../api/api";
import { config } from "../../../config";
import { useAppDispatch } from "../../../store";
import { ws } from "../../websocket/api/socketApi";
import { useLogoutUserMutation, useVerifyTokenMutation } from "../api/authApi";
import { authSelector, setToken } from "../data/authSlice";
import { getAuthTokenData } from "../services/authStorage";

export const useAuthentication = () => {
    const dispatch = useAppDispatch();
    const { authToken } = useSelector(authSelector);
    const [verifyToken, { isLoading }] = useVerifyTokenMutation();
    const navigate = useNavigate();
    const toast = useToast();

    const [logout] = useLogoutUserMutation();

    const resetApp = useCallback(() => {
        dispatch(setToken(""));
        dispatch(api.util.resetApiState());
        if (config.isDevEnv) {
            navigate("/");
        } else {
            navigate("https://cleanharbour.io/");
        }
    }, [dispatch, navigate]);

    useEffect(() => {
        const checkAuthToken = async () => {
            const token = getAuthTokenData();
            if (token) {
                try {
                    await verifyToken({ token }).unwrap();
                    dispatch(setToken(token));
                } catch (err) {
                    resetApp();
                }
            }
        };
        checkAuthToken();
    }, [dispatch, verifyToken, resetApp]);

    const logoutUser = async () => {
        try {
            await logout().unwrap();
            dispatch(setToken(""));
            if (ws) {
                ws.close();
            }
            resetApp();
            toast({
                title: "Success",
                description: "You have successfully logged out",
                status: "success",
                duration: 3000
            });
        } catch (e) {
            toast({
                title: "Error",
                description: "An error occurred while logging out. Please try again.",
                status: "success",
                duration: 3000
            });
        }
    };

    return {
        isAuthenticated: Boolean(authToken),
        logout: logoutUser,
        loadingVerification: isLoading,
        resetApp
    };
};
