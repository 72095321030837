import "./style.css";

export const TypingAnim = () => {
    return (
        <div className='container'>
            <div className='dot'></div>
            <div className='dot'></div>
            <div className='dot'></div>
        </div>
    );
};
