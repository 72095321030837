import { Button, FormControl, FormErrorMessage, Image, Input, Textarea, VStack, useToast } from "@chakra-ui/react";
import { FieldError, SubmitHandler, useForm } from "react-hook-form";

import logo from "../../../assets/images/logo.png";
import { AuthLayout } from "../../auth/components/AuthLayout";
import { useBookDemoMutation } from "../api/landingApi";
import { GradientText } from "./Landing";

type BookDemoInputs = {
    name: string;
    email: string;
    phone: string;
    company: string;
    jobTitle: string;
    message: string;
};

export const BookDemo = () => {
    const toast = useToast();
    const [book, { isLoading }] = useBookDemoMutation();
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm<BookDemoInputs>();

    const onBookSubmit: SubmitHandler<BookDemoInputs> = async data => {
        try {
            await book({
                name: data.name,
                email: data.email,
                phone: data.phone,
                company_name: data.company,
                job_title: data.jobTitle,
                message: data.message
            }).unwrap();
            toast({
                title: "We have received your request. We will get back to you soon.",
                status: "success",
                duration: 4000,
                isClosable: true
            });
            reset();
        } catch (error) {
            toast({
                title: "An error occurred",
                description: "Please try again later",
                status: "error",
                duration: 9000,
                isClosable: true
            });
        }
    };

    const fields: { id: keyof BookDemoInputs; placeholder: string; rules: any; error?: FieldError }[] = [
        {
            id: "name",
            placeholder: "Your name",
            rules: {
                required: "This field is required",
                minLength: {
                    value: 4,
                    message: "Minimum length should be 4"
                }
            },
            error: errors.name
        },
        {
            id: "email",
            placeholder: "E-mail",
            rules: {
                required: "This field is required",
                minLength: {
                    value: 4,
                    message: "Minimum length should be 4"
                },
                pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid email address"
                }
            },
            error: errors.email
        },
        {
            id: "phone",
            placeholder: "Phone",
            rules: {
                required: "This field is required",
                minLength: {
                    value: 4,
                    message: "Minimum length should be 4"
                },
                pattern: {
                    value: /^[0-9]+$/,
                    message: "Invalid phone number"
                }
            },
            error: errors.phone
        },
        {
            id: "company",
            placeholder: "Company",
            rules: {
                required: "This field is required"
            },
            error: errors.company
        },
        {
            id: "jobTitle",
            placeholder: "Job Title",
            rules: {
                required: "This field is required"
            },
            error: errors.jobTitle
        },
        {
            id: "message",
            placeholder: "Message",
            rules: {},
            error: errors.message
        }
    ];
    return (
        <AuthLayout screen='register'>
            <VStack>
                <Image src={logo} alt='logo' w='2rem' />
                <GradientText fontSize='3xl'>Book a demo</GradientText>
            </VStack>
            <form
                style={{ width: "100%" }}
                onSubmit={e => {
                    e.preventDefault();
                    handleSubmit(onBookSubmit);
                }}>
                <VStack
                    w={{ base: "100%", md: "100%" }}
                    p={{ base: ".5rem", md: "1.5rem" }}
                    borderRadius='xl'
                    boxShadow={{ base: "none", md: "#34CC82 0px 0px .4rem" }}
                    gap={{ base: "1.2rem", md: "2rem" }}>
                    {fields.map(field => {
                        return (
                            <FormControl isInvalid={Boolean(field.error)} key={field.id}>
                                {field.id === "message" ? (
                                    <Textarea placeholder={field.placeholder} id={field.id} {...register(field.id, field.rules)} />
                                ) : (
                                    <Input placeholder={field.placeholder} id={field.id} {...register(field.id, field.rules)} />
                                )}
                                <FormErrorMessage>{field.error && field.error.message}</FormErrorMessage>
                            </FormControl>
                        );
                    })}
                    <Button w='full' type='submit' onClick={handleSubmit(onBookSubmit)} isLoading={isLoading}>
                        Submit
                    </Button>
                </VStack>
            </form>
        </AuthLayout>
    );
};
