import { Box, useToast } from "@chakra-ui/react";
import { useState } from "react";
import {
    FieldErrors,
    SubmitHandler,
    UseFormClearErrors,
    UseFormRegister,
    UseFormSetError,
    UseFormSetValue,
    UseFormUnregister,
    UseFormWatch,
    useForm
} from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import { useCreateProjectsMutation } from "../api/projectsApi";
import { ProjectCreateType } from "../api/types";
import { Details } from "../components/CreateProject/Details";
import { Documents } from "../components/CreateProject/Documents";
import { Geography } from "../components/CreateProject/Geography";
import { Info } from "../components/CreateProject/Info";
import { Investment } from "../components/CreateProject/Investment";
import { Privacy } from "../components/CreateProject/Privacy";
import { Review } from "../components/CreateProject/Review";
import { FormLayout } from "../components/FormLayout";
import { FormSteps } from "../components/FormSteps";

export type SetValueType = UseFormSetValue<ProjectCreateType>;
export type SetErrorType = UseFormSetError<ProjectCreateType>;
export type WatchType = UseFormWatch<ProjectCreateType>;
export type ClearErrorsType = UseFormClearErrors<ProjectCreateType>;
export type ErrorsType = FieldErrors<ProjectCreateType>;
export type UnregisterType = UseFormUnregister<ProjectCreateType>;
export type RegisterType = UseFormRegister<ProjectCreateType>;

export const ProjectCreate = () => {
    const toast = useToast();
    const { portfolioId } = useParams<{ portfolioId: string }>();
    const [createNewProject, { isLoading: isLoadingCreate }] = useCreateProjectsMutation();
    const [currentIndex, setCurrentIndex] = useState(0);
    const [files, setFiles] = useState<File[]>([]);

    const [nda, setNda] = useState<File | null>(null);
    const [ndaSource, setNdaSource] = useState<"own" | "template">("own");

    const navigate = useNavigate();

    const steps = ["Info", "Details", "Geography", "Investment", "Documents", "Privacy", "Review"];

    const {
        handleSubmit,
        setValue,
        watch,
        setError,
        clearErrors,
        unregister,
        register,
        formState: { errors }
    } = useForm<ProjectCreateType>();

    const onSubmit: SubmitHandler<ProjectCreateType> = async data => {
        try {
            const formData = new FormData();

            const projectCreate = { ...data };

            const isPortfolio = Boolean(portfolioId);
            if (isPortfolio) {
                projectCreate.portfolio = Number(portfolioId);
            }

            Object.entries(projectCreate).forEach(([key, value]) => {
                formData.append(key, String(value));
            });
            if (nda) {
                formData.append("nda_file", nda);
            }
            formData.append("is_cleanharbour_template", ndaSource === "template" ? "true" : "false");

            if (files.length > 0) {
                files.forEach(file => {
                    formData.append("documents", file);
                });
            }

            const res = await createNewProject({ formData }).unwrap();
            toast({
                title: "Success!",
                description: isPortfolio ? "Project added to portfolio successfully" : "Project created successfully",
                status: "success",
                duration: 4000
            });
            navigate("/projects/" + res.slug);
        } catch (err) {
            return err;
        }
    };

    const formStep: Record<number, JSX.Element> = {
        0: (
            <Info
                setCurrentIndex={setCurrentIndex}
                setValue={setValue}
                name={watch("name")}
                description={watch("description")}
                errors={errors}
                setError={setError}
                clearErrors={clearErrors}
            />
        ),
        1: (
            <Details
                setCurrentIndex={setCurrentIndex}
                setValue={setValue}
                energy={watch("energy_type")}
                status={watch("status")}
                capacity={watch("capacity")}
                errors={errors}
                setError={setError}
                clearErrors={clearErrors}
                unregister={unregister}
            />
        ),
        2: (
            <Geography
                setCurrentIndex={setCurrentIndex}
                setValue={setValue}
                errors={errors}
                setError={setError}
                country={watch("country")}
                location={watch("location")}
                clearErrors={clearErrors}
            />
        ),
        3: (
            <Investment
                setCurrentIndex={setCurrentIndex}
                setValue={setValue}
                errors={errors}
                setError={setError}
                clearErrors={clearErrors}
                min_investment={watch("min_investment")}
                target_amount={watch("target_amount")}
                yield_to_maturity={watch("yield_to_maturity")}
                deadline={watch("deadline_years")}
                register={register}
            />
        ),
        4: <Documents setCurrentIndex={setCurrentIndex} files={files} setFiles={setFiles} />,
        5: (
            <Privacy
                setCurrentIndex={setCurrentIndex}
                setValue={setValue}
                isPrivate={watch("is_private")}
                nda={nda}
                setNda={setNda}
                ndaSource={ndaSource}
                setNdaSource={setNdaSource}
            />
        ),
        6: <Review onProjectSubmit={handleSubmit(onSubmit)} watch={watch} files={files} nda={nda} ndaSource={ndaSource} />
    };

    const currentFormStep = formStep[currentIndex];

    return (
        <Box maxW='50rem' mx='auto'>
            <FormSteps steps={steps} currentIndex={currentIndex} setCurrentIndex={setCurrentIndex} />
            <FormLayout title={steps[currentIndex]} isLoadingCreate={isLoadingCreate}>
                {currentFormStep}
            </FormLayout>
        </Box>
    );
};
