import { IconType } from ".";

export const FanIcon = ({ size = 24, color = "#03C988" }: IconType) => (
    <svg width={size} height={size} viewBox='0 0 24 24' fill='none'>
        <g clipPath='url(#clip0_93_2040)'>
            <path
                d='M12 14.25C13.2426 14.25 14.25 13.2426 14.25 12C14.25 10.7574 13.2426 9.75 12 9.75C10.7574 9.75 9.75 10.7574 9.75 12C9.75 13.2426 10.7574 14.25 12 14.25Z'
                stroke={color}
                strokeWidth={1.5}
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M9.77905 11.6381C9.1556 11.3794 8.59268 10.994 8.12594 10.5063C7.6592 10.0187 7.29882 9.4394 7.06763 8.80521C6.83643 8.17103 6.73946 7.49574 6.78285 6.82212C6.82624 6.14851 7.00905 5.49124 7.3197 4.89196C7.63035 4.29268 8.06207 3.76444 8.58751 3.3407C9.11295 2.91697 9.72067 2.60697 10.3722 2.43034C11.0237 2.25372 11.7047 2.21432 12.3723 2.31463C13.0398 2.41495 13.6792 2.6528 14.25 3.01311L12.5465 9.81374'
                stroke={color}
                strokeWidth={1.5}
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M12.7969 14.1047C12.886 14.7747 12.8349 15.456 12.6467 16.1052C12.4584 16.7544 12.1373 17.3573 11.7035 17.8758C11.2698 18.3942 10.733 18.8168 10.1272 19.1167C9.52146 19.4166 8.85994 19.5872 8.18469 19.6177C7.50944 19.6482 6.83521 19.538 6.20484 19.294C5.57447 19.0501 5.00172 18.6777 4.52298 18.2005C4.04423 17.7233 3.66995 17.1518 3.42391 16.5222C3.17787 15.8926 3.06544 15.2188 3.09376 14.5434L9.83532 12.6178'
                stroke={color}
                strokeWidth={1.5}
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M13.4241 10.2572C13.9597 9.84497 14.5753 9.54866 15.2316 9.38705C15.8879 9.22545 16.5706 9.20209 17.2364 9.31846C17.9023 9.43483 18.5366 9.68838 19.0992 10.063C19.6618 10.4376 20.1403 10.9252 20.5044 11.4946C20.8685 12.0641 21.1102 12.7031 21.2141 13.3709C21.3181 14.0388 21.282 14.721 21.1082 15.3742C20.9344 16.0273 20.6267 16.6372 20.2046 17.1651C19.7825 17.693 19.2552 18.1274 18.6563 18.4406L13.6181 13.5656'
                stroke={color}
                strokeWidth={1.5}
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </g>
        <defs>
            <clipPath id='clip0_93_2040'>
                <rect width={24} height={24} fill='white' />
            </clipPath>
        </defs>
    </svg>
);
