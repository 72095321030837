import { extendTheme } from "@chakra-ui/react";
import { MultiSelectTheme } from "chakra-multiselect";

export const theme = extendTheme({
    fonts: {
        heading: `"Barlow", sans-serif;`,
        body: `"Barlow", sans-serif;`
    },
    breakpoints: {
        base: "0em",
        sm: "30em",
        md: "48em",
        lg: "62em",
        xl: "80em",
        "2xl": "96em"
    },
    colors: {
        green: {
            50: "#A8F2D1",
            100: "#8CE8C1",
            200: "#6EDFAD",
            300: "#50D697",
            400: "#34CC82",
            500: "#03C988",
            600: "#02B56E",
            700: "#029F63",
            800: "#018A57",
            900: "#00754B"
        }
    },
    components: {
        MultiSelect: MultiSelectTheme,
        Button: {
            defaultProps: {
                colorScheme: "green"
            }
        },
        Input: {
            baseStyle: {
                backgroundColor: "blue.100"
            }
        },
        Switch: {
            defaultProps: {
                colorScheme: "green"
            }
        },
        Radio: {
            defaultProps: {
                colorScheme: "green"
            }
        }
    }
});
