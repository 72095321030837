import { WarningIcon } from "@chakra-ui/icons";
import {
    Badge,
    Box,
    Button,
    Circle,
    Flex,
    FormControl,
    FormLabel,
    HStack,
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    Slider,
    SliderFilledTrack,
    SliderThumb,
    SliderTrack,
    Switch,
    Text,
    VStack,
    useDisclosure,
    useTheme,
    useToast
} from "@chakra-ui/react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { DownloadIcon } from "../../../assets/icons/download";
import { CustomModal } from "../../../components/CustomModal";
import { useAppDispatch } from "../../../store";
import { Arc } from "../../explore/components/charts/Arc";
import { MessageTypes, sendToWs } from "../../websocket/api/socketApi";
import { setNBOCreated, socketSelector } from "../../websocket/data/socketSlice";
import { useGetProjectDetailsQuery, useLazyCreateNDArequestQuery } from "../api/projectsApi";
import { EnergyBadge } from "../components/EnergyBadge";
import { HeaderSearch } from "../components/HeaderSearch";
import { ProjectSuggestionsRow } from "../components/ProjectSuggestionsRow";
import { StatusBadge } from "../components/StatusBadge";

export const ProjectDetails = () => {
    const { colors } = useTheme();
    const dispatch = useAppDispatch();
    const toast = useToast();

    const data = {
        labels: ["Red", "Blue"],
        datasets: [
            {
                label: "# of Votes",
                data: [90, 20],
                backgroundColor: [colors.green[400], colors.gray[300]],
                borderColor: [colors.green[400], colors.gray[300]],
                borderWidth: 1
            }
        ]
    };
    const { slug = "" } = useParams<{ slug: string }>();
    const { isOpen, onOpen, onClose } = useDisclosure();

    const { data: project, isLoading } = useGetProjectDetailsQuery({ slug });
    const [requestNda, { isLoading: loadingNdaRequest }] = useLazyCreateNDArequestQuery();
    const { isLoading: loadingSocket } = useSelector(socketSelector);
    const [binding, setBinding] = useState(project?.target_amount || 0);

    if (isLoading) return <div>Loading...</div>;
    if (!project) return <div>Project not found</div>;

    const hasDocuments = project.num_documents > 0;

    const startChat = () => {
        sendToWs({
            msg_type: MessageTypes.DialogCreated,
            recipient_id: String(project.creator.id)
        });
    };

    const sendNBO = () => {
        sendToWs({
            msg_type: MessageTypes.CreateNbo,
            project_id: String(project.id),
            amount: String(binding),
            currency: "USD"
        });
        // if the project is private, we set the NBO as created otherwise we need to wait for socket to respond
        if (project.is_private) {
            dispatch(setNBOCreated(true));
        }
        onClose();
    };

    const isPrivate = project.is_private;

    const getDocumentsRows = () => {
        if (hasDocuments && !isPrivate) {
            return project.documents.map(doc => (
                <HStack key={doc.id} justifyContent='space-between' w='full' borderBottomWidth='1px' p='1rem'>
                    <Text>{doc.title}</Text>
                    <Button
                        as={"a"}
                        colorScheme='white'
                        shadow='sm'
                        textColor='black'
                        borderWidth='1px'
                        href={doc.file}
                        target='_blank'
                        isDisabled={isPrivate}>
                        <DownloadIcon />
                        Download
                    </Button>
                </HStack>
            ));
        }

        if (hasDocuments && isPrivate) {
            return Array.from({ length: project.num_documents }, (_, i) => (
                <HStack key={i} justifyContent='space-between' w='full' borderBottomWidth='1px' p='1rem' filter='blur(10px)'>
                    <Text>Private doc</Text>
                    <Button colorScheme='white' shadow='sm' textColor='black' borderWidth='1px' isDisabled>
                        <DownloadIcon />
                        Download
                    </Button>
                </HStack>
            ));
        }

        return (
            <VStack p='4'>
                <Text>No documents</Text>
            </VStack>
        );
    };

    const onNDARequest = async () => {
        try {
            await requestNda({ projectSlug: project.slug }).unwrap();
            toast({
                title: "NDA request sent",
                status: "success",
                duration: 4000,
                isClosable: true
            });
        } catch (err) {
            toast({
                title: "Error",
                description: "An error occurred while sending the NDA request",
                status: "error",
                duration: 4000,
                isClosable: true
            });
            return err;
        }
    };

    const getNDAButton = () => {
        if (!project.is_private) return null;
        if (project.has_nda_request) {
            return (
                <Text color='green.500' fontWeight='bold'>
                    NDA requested
                </Text>
            );
        }
        return <Button variant="ghost" onClick={onNDARequest}>Request NDA</Button>;
    };

    return (
        <VStack w='full' spacing='5' alignItems='flex-start'>
            <HeaderSearch value={""} onChange={() => null} />

            <Box bg='white' p='1rem' borderRadius='md' w='full'>
                <Flex gap='2rem' direction={{ base: "column", md: "row" }} alignItems='center'>
                    <Box
                        w={{ base: "100%", md: "30%" }}
                        h={{ base: "10rem", md: "20rem" }}
                        bgImage={`url(${project.energy_type.image})`}
                        bgSize='cover'
                        bgPosition='center'
                        borderRadius='md'
                    />
                    <VStack spacing='2' alignItems='flex-start' w={{ base: "100%", md: "70%" }}>
                        <HStack alignItems='center' justifyContent='space-between' w='full'>
                            <HStack>
                                <EnergyBadge energyType={project.energy_type.name} />
                                <StatusBadge status={project.status.name} />
                            </HStack>
                            {isPrivate && (
                                <Badge variant='subtle' colorScheme='yellow'>
                                    <WarningIcon mr='1' />
                                    This project has some private information
                                </Badge>
                            )}
                        </HStack>
                        <HStack alignItems='center'>
                            <Text fontWeight='bold' fontSize={{ base: "xl", md: "2xl" }}>
                                {project.name}
                            </Text>
                            <Text fontSize={{ base: "lg", md: "xl" }}>
                                {project.location.name}, {project.location.country.name}
                            </Text>
                        </HStack>
                        <Text whiteSpace='pre-wrap' overflowY='auto' maxH='12rem'>
                            {project.description}
                        </Text>

                        {!project.is_own_project && (
                            <HStack spacing='4' alignSelf='flex-end'>
                                {getNDAButton()}
                                <Button variant='outline' onClick={startChat} isLoading={loadingSocket} isDisabled={isPrivate}>
                                    Chat
                                </Button>
                                {project.has_nbo ? (
                                    <Text color='green.500' fontWeight='bold'>
                                        nbo created for this project
                                    </Text>
                                ) : (
                                    <Button onClick={onOpen} isDisabled={isPrivate}>
                                        Send a NBO
                                    </Button>
                                )}
                            </HStack>
                        )}
                    </VStack>
                </Flex>
            </Box>

            <Flex gap='1rem' w='full' h={{ base: "auto", lg: "20rem" }} direction={{ base: "column", lg: "row" }}>
                <VStack spacing='.8rem' flexGrow='.5' h='full'>
                    <HStack spacing='.8rem' flexGrow='1' w='full' h='10rem'>
                        <Box bg='#F64E60' borderRadius='md' h='full' w='full' display='flex' alignItems='flex-end' p='1rem' color='white'>
                            <VStack spacing='0' alignItems='flex-start' color='white'>
                                <Text fontWeight='bold'>{project.emissions_avoided_mt}</Text>
                                <Text fontSize='sm'>CO2 Emissions Avoided</Text>
                            </VStack>
                        </Box>
                        <Box bg='#1BC5BD' borderRadius='md' h='full' w='full' display='flex' alignItems='flex-end' p='1rem'>
                            <VStack spacing='0' alignItems='flex-start' color='white'>
                                <Text fontWeight='bold'>
                                    {project.deadline_years} year
                                    {project.deadline_years !== 1 ? "s" : ""}
                                </Text>
                                <Text fontSize='sm'>Development Maturity</Text>
                            </VStack>
                        </Box>
                    </HStack>
                    <HStack spacing='.8rem' flexGrow='1' w='full' h='10rem'>
                        <Box bg='#3699FF' borderRadius='md' flexGrow='1' w='full' display='flex' alignItems='flex-end' p='1rem' h='full'>
                            <VStack spacing='0' alignItems='flex-start' color='white'>
                                <Text fontWeight='bold'>{project.energy_type.name}</Text>
                                <Text fontSize='sm'>Technology</Text>
                            </VStack>
                        </Box>
                        <Box
                            bg='green.500'
                            borderRadius='md'
                            flexGrow='1'
                            w='full'
                            display='flex'
                            alignItems='flex-end'
                            p='1rem'
                            h='full'
                            filter={isPrivate ? "blur(10px)" : undefined}>
                            <VStack fontSize='xs' alignItems='flex-start'>
                                <VStack spacing='0' alignItems='flex-start' color='white'>
                                    <Text fontWeight='bold'>{project?.yield_to_maturity || "N/A"} %</Text>
                                    <Text>Yield (ARR)</Text>
                                </VStack>
                                <VStack spacing='0' alignItems='flex-start' color='white'>
                                    <Text fontWeight='bold'>
                                        {project?.target_amount
                                            ? project.target_amount.toLocaleString("en-US", { style: "currency", currency: "USD" })
                                            : "N/A"}
                                    </Text>
                                    <Text>Target amount</Text>
                                </VStack>
                                <VStack spacing='0' alignItems='flex-start' color='white'>
                                    <Text fontWeight='bold'>
                                        {project?.min_investment
                                            ? project.min_investment.toLocaleString("en-US", { style: "currency", currency: "USD" })
                                            : "N/A"}
                                    </Text>
                                    <Text>Minimum investment</Text>
                                </VStack>
                            </VStack>
                        </Box>
                    </HStack>
                </VStack>
                <Box bg='white' borderRadius='md' flexGrow='6' h='full' p='1rem'>
                    <Flex gap='3rem' alignItems='center' justifyContent='center' h='full' direction={{ base: "column", lg: "row" }}>
                        <VStack>
                            <Text fontWeight='bold'>Projected Settlement Value</Text>
                            <Text fontSize='sm'>
                                Top{" "}
                                <Text fontWeight='bold' color='green.500' display='inline'>
                                    44%
                                </Text>{" "}
                                of project
                            </Text>
                            <Arc data={data} size={200} />
                        </VStack>
                        <VStack>
                            <Text fontWeight='bold'>GOs Projected</Text>
                            <Flex position='relative' alignItems='center' justifyContent='center'>
                                <Circle size={{ base: "16rem", lg: "12rem" }} bg='green.200' borderColor='green.500' borderWidth='3px' />
                                <VStack position='absolute' spacing='0'>
                                    <Text fontWeight='bold' fontSize='2xl' color='green.800'>
                                        {project.gos_projected}
                                    </Text>
                                    <Text>annually</Text>
                                </VStack>
                            </Flex>
                        </VStack>
                        <VStack>
                            <Text fontWeight='bold'>CO2 Emission Avoided</Text>
                            <Flex position='relative' alignItems='center' justifyContent='center'>
                                <Circle size={{ base: "16rem", lg: "12rem" }} bg='blue.200' borderColor='blue.500' borderWidth='3px' />
                                <VStack position='absolute' spacing='0'>
                                    <Text fontWeight='bold' fontSize='2xl' color='blue.800'>
                                        {project.emissions_avoided}
                                    </Text>
                                    <Text>matric year</Text>
                                </VStack>
                            </Flex>
                        </VStack>
                    </Flex>
                </Box>
            </Flex>

            <HStack spacing='5' w='full'>
                <Box flexGrow='1' bg='white' borderRadius='md'>
                    <HStack justifyContent='space-between' borderBottomWidth='1px' p='1rem' mb='1rem'>
                        <Text fontWeight='bold'>Documents</Text>
                        {hasDocuments && project.num_documents > 1 && (
                            <Button colorScheme='white' shadow='sm' textColor='black' borderWidth='1px' isDisabled={isPrivate}>
                                <DownloadIcon />
                                Download all
                            </Button>
                        )}
                    </HStack>

                    <VStack>{getDocumentsRows()}</VStack>
                </Box>
            </HStack>
            {project.suggestions.length > 0 && (
                <VStack flexGrow='1' bg='white' borderRadius='md' p='1rem' w='full' alignItems='flex-start'>
                    <VStack spacing='4' w='full' alignItems='flex-start'>
                        <Text fontWeight='bold'>You may also like</Text>
                        <Flex gap='.5rem' w='full' alignItems='flex-start' overflowX='auto' direction={{ base: "column", md: "row" }}>
                            {project.suggestions.map(suggestion => (
                                <ProjectSuggestionsRow key={suggestion.slug} project={suggestion} />
                            ))}
                        </Flex>
                    </VStack>
                </VStack>
            )}
            <CustomModal isOpen={isOpen} onClose={onClose} title='Non binding offer' confirmText='Send NBO' onConfirm={sendNBO}>
                <NBOModalContent setBinding={setBinding} binding={binding} max={project?.target_amount || 10000000} />
            </CustomModal>
        </VStack>
    );
};

type NBOModalContentProps = {
    binding: number;
    setBinding: (value: number) => void;
    max: number;
};

const NBOModalContent = ({ binding, setBinding, max }: NBOModalContentProps) => {
    const [isManual, setIsManual] = useState(false);

    const MIN = 100000;

    const formatOffer = (value: number) => {
        return value.toLocaleString("en-US", {
            style: "currency",
            currency: "USD"
        });
    };
    return (
        <VStack alignItems='center' spacing='1rem'>
            <FormControl display='flex' alignSelf='flex-end' w='auto'>
                <FormLabel htmlFor='edit-nbo' mb='0' mr='2' fontSize='sm'>
                    Manual input
                </FormLabel>
                <Switch id='edit-nbo' isChecked={isManual} onChange={() => setIsManual(!isManual)} />
            </FormControl>
            {isManual ? (
                <NumberInput
                    defaultValue={formatOffer(binding)}
                    min={MIN}
                    max={max}
                    precision={2}
                    step={500}
                    value={formatOffer(binding)}
                    onChange={value => setBinding(Number(value))}>
                    <NumberInputField fontSize='4xl' fontWeight='bold' py='5' textAlign='center' value={binding} />
                    <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                    </NumberInputStepper>
                </NumberInput>
            ) : (
                <Text fontSize='4xl' fontWeight='bold' textAlign='center'>
                    {formatOffer(binding)}
                </Text>
            )}
            {!isManual ? (
                <Slider colorScheme='green' aria-label='slider-binding' defaultValue={binding} onChange={setBinding} min={1000} max={100000}>
                    <SliderTrack>
                        <SliderFilledTrack />
                    </SliderTrack>
                    <SliderThumb />
                </Slider>
            ) : null}
        </VStack>
    );
};
