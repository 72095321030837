import { HStack, Text, VStack } from "@chakra-ui/react";

import { getDateAndTime } from "../../../utils/date";
import { NewsType } from "../api/types";

type NewsCardProps = {
    news: NewsType;
};

export const NewsCard = ({ news }: NewsCardProps) => {
    return (
        <a href={news.url} target='blank'>
            <HStack w='full' borderWidth='1' spacing='1rem' alignItems='flex-start'>
                <VStack flexGrow='1' w='100%' alignItems='flex-start' spacing='0'>
                    <Text fontSize='sm' fontWeight='bold'>
                        <div dangerouslySetInnerHTML={{ __html: news.title }} />
                    </Text>
                    <Text fontSize='xs' mb='2'>
                        <div dangerouslySetInnerHTML={{ __html: news.description }} />
                    </Text>
                    <Text fontStyle='italic' fontSize='xs'>
                        {news.source}
                    </Text>
                    <Text fontSize='xs'>Published at {getDateAndTime(new Date(news.published_at))}</Text>
                </VStack>
            </HStack>
        </a>
    );
};
