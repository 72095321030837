import { Button, FormControl, FormErrorMessage, Heading, Input, Text, VStack, useToast } from "@chakra-ui/react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { useAppDispatch } from "../../../store";
import { useLoginUserMutation } from "../api/authApi";
import { AuthLayout } from "../components/AuthLayout";
import { setToken } from "../data/authSlice";
import { saveRefreshTokenData } from "../services/authStorage";

type LoginInputs = {
    email: string;
    password: string;
};

export const Login = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [loginUser, { isLoading }] = useLoginUserMutation();
    const toast = useToast();

    const {
        register,
        handleSubmit,
        setError,
        clearErrors,
        formState: { errors }
    } = useForm<LoginInputs>();

    const onLogin: SubmitHandler<LoginInputs> = async data => {
        try {
            const res = await loginUser({
                ...data
            }).unwrap();

            const token = res.access;
            const refresh = res.refresh;
            dispatch(setToken(token));
            saveRefreshTokenData(refresh);

            toast({
                title: "Success!",
                description: "You have successfully logged in",
                status: "success",
                duration: 3000
            });
            clearErrors();

            navigate("/dashboard", { replace: true });
        } catch (err: any) {
            if (err?.data) {
                const errors: [string, string[]][] = Object.entries(err.data);

                errors.forEach(([key, value]) => {
                    if (key === "non_field_errors") {
                        toast({
                            title: "Error!",
                            description: value[0],
                            status: "error",
                            duration: 5000
                        });
                    } else {
                        if (["email", "password"].includes(key)) {
                            setError(key as "email" | "password", { message: value[0] });
                        }
                    }
                });
            }
        }
    };

    return (
        <AuthLayout screen='login'>
            <VStack mb='6'>
                <Heading size='lg'>Welcome back</Heading>
                <Text color='gray' size='sm'>
                    Welcome back! Please enter your details.
                </Text>
            </VStack>
            <form
                onSubmit={e => {
                    e.preventDefault();
                    handleSubmit(onLogin);
                }}
                style={{ width: "100%" }}>
                <FormControl isInvalid={Boolean(errors.email)} mb='3'>
                    <Input
                        placeholder='Email'
                        id='email'
                        {...register("email", {
                            required: "This field is required",
                            minLength: {
                                value: 4,
                                message: "Minimum length should be 4"
                            },
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: "Invalid email address"
                            }
                        })}
                    />
                    <FormErrorMessage>{errors.email && errors.email.message}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={Boolean(errors.password)}>
                    <Input
                        placeholder='Password'
                        id='password'
                        type='password'
                        {...register("password", {
                            required: "This field is required"
                        })}
                    />
                    <FormErrorMessage>{errors.password && errors.password.message}</FormErrorMessage>
                </FormControl>
                <Button type='submit' w='full' onClick={handleSubmit(onLogin)} mt='6' isLoading={isLoading}>
                    Sign in
                </Button>
            </form>
        </AuthLayout>
    );
};
