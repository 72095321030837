import { Box, Text, TypographyProps } from "@chakra-ui/react";

import { ProjectStatusText } from "../api/types";

export const StatusBadge = ({ status, fontSize = "xs" }: { status: ProjectStatusText; fontSize?: TypographyProps["fontSize"] }) => {
    return (
        <Box py='.5' px='2' borderRadius='xl' bg='gray.200' borderWidth='1px' borderColor='gray.700'>
            <Text fontSize={fontSize} color='gray.700'>
                {status}
            </Text>
        </Box>
    );
};
