import { Box } from "@chakra-ui/react";
import { ArcElement, ChartData, Chart as ChartJS, ChartOptions, Legend, Tooltip } from "chart.js";
import React from "react";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

type ArcChartProps = {
    data: ChartData<"doughnut">;
    label?: React.ReactNode;
    size?: number;
};

export const Arc = ({ data, label, size = 300 }: ArcChartProps) => {
    const options: ChartOptions<"doughnut"> = {
        //@ts-ignore
        cutout: "80%",
        aspectRatio: 1,
        rotation: 270,
        circumference: 180,
        responsive: true,
        plugins: {
            legend: {
                display: false
            },

            tooltip: {
                enabled: false
            }
        },
        elements: {
            arc: {
                // spacing: -20,
                // borderRadius(ctx, options) {
                //     const index = ctx.dataIndex;
                //     const count = ctx.dataset.data.length;
                //     if (index === 0 || index === count - 1) {
                //         return 10; // Apply border radius to the first and last arc
                //     }
                //     return 0; // No border radius for other arcs
                // }
            }
        }
    };

    return (
        <Box position='relative' display='flex' alignItems='center' justifyContent='center'>
            <Doughnut data={data} options={options} width={size} height={size / 2} />
            {label && (
                <Box position='absolute' bottom='10'>
                    {label}
                </Box>
            )}
        </Box>
    );
};
