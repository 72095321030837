import { ChevronLeftIcon } from "@chakra-ui/icons";
import { Box, HStack, IconButton, VStack } from "@chakra-ui/react";
import { Link } from "react-router-dom";

import panelsImg from "../../../assets/images/photo-panels.jpg";
import windImg from "../../../assets/images/wind-energy.jpg";
import { Layout } from "../../../components/Layout";
import { config } from "../../../config";

type AuthLayoutProps = {
    children: React.ReactNode;
    screen: "login" | "register";
};

export const AuthLayout = ({ children, screen }: AuthLayoutProps) => {
    const goBack = config.isDevEnv ? "/" : "https://cleanharbour.io/";
    return (
        <Layout>
            <HStack>
                <VStack w={{ base: "100%", md: "50%" }} p='4' alignSelf={{ base: "center" }} h='100vh' justifyContent='center'>
                    <Link to={goBack}>
                        <IconButton aria-label='Send email' icon={<ChevronLeftIcon />} isRound position='absolute' top='2rem' left='2rem' />
                    </Link>
                    <VStack w='24rem' justifyContent='center' spacing='4' bg='white' p='4' borderRadius='lg' mx='1rem' m='4'>
                        {children}
                    </VStack>
                </VStack>
                <Box
                    w={{ base: "100%", md: "50%" }}
                    h='100vh'
                    bg='green.400'
                    borderTopStartRadius={{ base: "0", md: "3rem" }}
                    borderBottomLeftRadius={{ base: "0", md: "3rem" }}
                    backgroundImage={`url(${screen === "login" ? panelsImg : windImg})`}
                    backgroundSize='cover'
                    backgroundPosition='center'
                    position={{ base: "absolute", md: "relative" }}
                    zIndex={-1}
                    top='0'
                    right='0'
                />
            </HStack>
        </Layout>
    );
};
