import { useTheme } from "@chakra-ui/react";
import { geoNaturalEarth1, geoPath, max, min, scaleLinear, select } from "d3";
import { useEffect, useRef } from "react";

import { useResizeObserver } from "../hooks/useResizeObserver";

// https://www.youtube.com/watch?v=gGORNzKIXL4&t=9s
const GeoChart = ({ data, property, selectedCountry, setSelectedCountry }) => {
    const svgRef = useRef(null);
    const wrapperRef = useRef(null);
    const dimensions = useResizeObserver(wrapperRef);

    const { colors } = useTheme();

    // will be called initially and on every data change
    useEffect(() => {
        const svg = select(svgRef.current);

        const minProp = min(data.features, feature => feature.properties[property]);
        const maxProp = max(data.features, feature => feature.properties[property]);
        const colorScale = scaleLinear().domain([minProp, maxProp]).range([colors.gray[300], colors.gray[300]]);

        // use resized dimensions
        // but fall back to getBoundingClientRect, if no dimensions yet.
        const { width, height } = dimensions || wrapperRef.current?.getBoundingClientRect() || { width: 0, height: 0 };

        svg.attr("width", width);
        svg.attr("height", height);

        // projects geo-coordinates on a 2D plane
        const projection = geoNaturalEarth1()
            .fitSize([width, height], selectedCountry || data)
            .center([selectedCountry ? 1 : 20, 0])
            .precision(1000);

        // takes geojson data,
        // transforms that into the d attribute of a path element
        const pathGenerator = geoPath().projection(projection);

        // render text
        svg.selectAll(".label")
            .data([selectedCountry])
            .join("text")
            .attr("class", "label")
            .text(feature => feature && feature.properties.name + ": " + feature.properties[property].toLocaleString())
            .attr("x", 10)
            .attr("y", 25);

        // render each country
        svg.selectAll(".country")
            .data(data.features)
            .join("path")
            .on("click", (event, feature) => {
                setSelectedCountry(selectedCountry === feature ? null : feature);
            })
            .on("mouseover", (event, feature) => {
                select(event.currentTarget).attr("fill", colors.green[500]);
            })
            .on("mouseout", (event, feature) => {
                select(event.currentTarget).attr("fill", feature => colorScale(feature.properties[property]));
            })
            .attr("class", "country")
            .attr("stroke", "black") // add border color
            .attr("stroke-width", 0.5) // add border width
            .transition()
            .duration(1000)
            .attr("fill", feature => {
                if (selectedCountry && selectedCountry === feature) {
                    return colors.green[500];
                } else {
                    return colorScale(feature.properties[property]);
                }
            })
            .attr("d", feature => pathGenerator(feature));
    }, [data, dimensions, property, selectedCountry, colors, setSelectedCountry]);

    return (
        <div ref={wrapperRef} style={{ height: "850px" }}>
            <svg ref={svgRef}></svg>
        </div>
    );
};

export default GeoChart;
