import { api } from "../../../api/api";
import { NewsResponse } from "./types";

export const NEWS_LIST_TAG = "NewsList";
export const ALL_NEWS_LIST_TAG = "AllNewsList";

export const newsApi = api.enhanceEndpoints({ addTagTypes: [NEWS_LIST_TAG, ALL_NEWS_LIST_TAG] }).injectEndpoints({
    endpoints: build => ({
        getLastNews: build.query<NewsResponse, void>({
            query: () => ({
                method: "GET",
                url: "news/"
            }),
            providesTags: [NEWS_LIST_TAG]
        }),
        getAllNews: build.query<NewsResponse, { offset: number }>({
            query: ({ offset }) => ({
                method: "GET",
                url: "news/all/",
                params: { offset }
            }),
            providesTags: [ALL_NEWS_LIST_TAG]
        })
    })
});

export const { useGetLastNewsQuery, useGetAllNewsQuery } = newsApi;
