import { Button, FormControl, FormErrorMessage, FormLabel, Input, Textarea, VStack } from "@chakra-ui/react";

import { ClearErrorsType, ErrorsType, SetErrorType, SetValueType } from "../../pages/ProjectCreate";

type InfoProps = {
    setCurrentIndex: (index: number) => void;
    setValue: SetValueType;
    name: string;
    description: string;
    errors: ErrorsType;
    setError: SetErrorType;
    clearErrors: ClearErrorsType;
};

export const Info = ({ setCurrentIndex, setValue, name, description, errors, setError, clearErrors }: InfoProps) => {
    const onValidate = () => {
        clearErrors();
        if (!name) {
            setError("name", { message: "Name is required" });
            return;
        }

        if (!description) {
            setError("description", { message: "Description is required" });
            return;
        }

        setCurrentIndex(1);
    };
    return (
        <VStack spacing='1rem'>
            <FormControl isInvalid={Boolean(errors.name)}>
                <FormLabel htmlFor='name'>Project's name</FormLabel>
                <Input
                    id='name'
                    placeholder='Enter the project’s name (e.g., TerraVolt Solar Array)'
                    onChange={e => setValue("name", e.target.value)}
                    value={name}
                />
                <FormErrorMessage>{errors.name && errors.name.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={Boolean(errors.description)}>
                <FormLabel htmlFor='description'>Description</FormLabel>
                <Textarea
                    id='description'
                    placeholder='Provide a brief description of the project (e.g., Key Highlighs, Production forecast, Location advantage, grid connection), this will be the first impact investors will have with your project.'
                    value={description}
                    onChange={e => setValue("description", e.target.value)}
                />
                <FormErrorMessage>{errors.description && errors.description.message}</FormErrorMessage>
            </FormControl>
            <Button type='submit' onClick={onValidate} w='full' mt='2rem'>
                Next
            </Button>
        </VStack>
    );
};
