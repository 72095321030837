import { Search2Icon } from "@chakra-ui/icons";
import { Input, InputGroup, InputLeftElement, InputProps } from "@chakra-ui/react";
import { ChangeEventHandler } from "react";

type SearchBarProps = {
    value: string;
    onChange: ChangeEventHandler<HTMLInputElement>;
    placeholder?: string;
} & InputProps;

export const SearchBar = ({ onChange, value, placeholder, ...props }: SearchBarProps) => {
    return (
        <InputGroup maxW={{ base: "100%", md: "25rem" }}>
            <InputLeftElement h='100%'>
                <Search2Icon color='gray' />
            </InputLeftElement>

            <Input placeholder={placeholder} bg='white' value={value} onChange={onChange} {...props} />
        </InputGroup>
    );
};
