import { Alert, AlertIcon, Box, Drawer, DrawerCloseButton, DrawerContent, DrawerOverlay } from "@chakra-ui/react";
import { useState } from "react";

import { CountryPreview } from "../components/CountryPreview";
import GeoChart from "../components/GeoChart";
import data from "../utils/custom.geo.json";

export type GeoJsonType = typeof data;
export type FeatureType = GeoJsonType["features"][0];

export const Explore = () => {
    const [property, setProperty] = useState("pop_est");
    const [selectedCountry, setSelectedCountry] = useState<FeatureType | null>(null);

    return (
        <Box>
            <Drawer isOpen={Boolean(selectedCountry)} placement='right' onClose={() => setSelectedCountry(null)} size='md'>
                <DrawerOverlay bg='rgba(0,0,0,0.1)' />
                <DrawerContent w='20rem' p='1rem'>
                    <DrawerCloseButton />
                    <CountryPreview selectedCountry={selectedCountry} />
                </DrawerContent>
            </Drawer>
            <Alert status='warning' justifyContent='center' borderRadius='xl'>
                <AlertIcon />
                This section is under construction
            </Alert>
            <GeoChart data={data} property={property} selectedCountry={selectedCountry} setSelectedCountry={setSelectedCountry} />
        </Box>
    );
};
