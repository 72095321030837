import { Box, Flex, Heading, VStack } from "@chakra-ui/react";
import { ArcElement, BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from "chart.js";
import { Bar, Doughnut, Line } from "react-chartjs-2";

import { PieChart } from "../../explore/components/charts/Pie";
import {
    useGetAssetsAllocationsQuery,
    useGetGeographicalQuery,
    useGetNetCashflowQuery,
    useGetPerformanceOverviewQuery,
    useGetPortfolioValueQuery,
    useGetRealizedValueQuery,
    useGetRecsQuery
} from "../api/portfolioApi";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement);

export const Performance = () => {
    const { data: portfolioValue } = useGetPortfolioValueQuery();
    const { data: performanceData } = useGetPerformanceOverviewQuery();
    const { data: recsData } = useGetRecsQuery();
    const { data: realizedValue } = useGetRealizedValueQuery();
    const { data: assetsAllocations } = useGetAssetsAllocationsQuery();
    const { data: netCashflow } = useGetNetCashflowQuery();
    const { data: geographicalData } = useGetGeographicalQuery();

    if (!recsData || !realizedValue || !assetsAllocations || !netCashflow || !geographicalData || !portfolioValue) return null;
    return (
        <VStack w='full' spacing='1rem'>
            <Flex w='full' h='full' gap='1rem' alignItems='unset' direction={{ base: "column", md: "row" }}>
                <VStack w={{ base: "100%", md: "30%" }} spacing='1rem'>
                    <VStack w='full' justifyContent='center' bg='white' borderRadius='xl' p='3'>
                        <Heading textAlign='center' size='md'>
                            Portfolio value
                        </Heading>
                        <Heading textAlign='center' color='green.400'>
                            {portfolioValue?.value}
                        </Heading>
                    </VStack>
                    <Card>
                        <Doughnut data={assetsAllocations.data} options={assetsAllocations.options} />
                    </Card>
                </VStack>
                {performanceData && (
                    <Box w={{ base: "100%", md: "70%" }}>
                        <Card>
                            <Line data={performanceData.data} options={performanceData.options} />
                        </Card>
                    </Box>
                )}
            </Flex>
            <Flex gap='1rem' w='full' direction={{ base: "column", md: "row" }}>
                <Card>
                    <Bar options={recsData.options} data={recsData.data} />
                </Card>
                <Card>
                    <Bar options={realizedValue.options} data={realizedValue.data} />
                </Card>
            </Flex>
            <Flex gap='1rem' w='full' direction={{ base: "column", md: "row" }}>
                <Card>
                    <Bar options={netCashflow.options} data={netCashflow.data} />
                </Card>
                <Card>
                    <PieChart labels={geographicalData.labels} data={geographicalData.data} aspectRatio={2} />
                </Card>
            </Flex>
        </VStack>
    );
};

const Card = ({ children }: { children: React.ReactNode }) => {
    return (
        <Box bg='white' borderRadius='xl' p='3' flexGrow='1' h='full' w='full' overflow='auto'>
            {children}
        </Box>
    );
};
