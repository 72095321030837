import { combineReducers } from "@reduxjs/toolkit";

import { api } from "../api/api";
import authReducer from "../modules/auth/data/authSlice";
import chatReducer from "../modules/chat/data/chatSlice";
import socketReducer from "../modules/websocket/data/socketSlice";

const rootReducer = combineReducers({
    [api.reducerPath]: api.reducer,
    auth: authReducer,
    chat: chatReducer,
    socket: socketReducer
});
export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
