import { Box, Heading, Spinner, Stack, Text } from "@chakra-ui/react";

type FormLayoutProps = {
    children: React.ReactNode;
    title: string;
    isLoadingCreate: boolean;
};

export const FormLayout = ({ children, title, isLoadingCreate }: FormLayoutProps) => {
    const isLoading = isLoadingCreate;
    return (
        <Box bg='white' p='1rem' borderRadius='lg' shadow='lg'>
            <Heading mb='1.5rem' size='lg'>
                {title}
            </Heading>
            {isLoading ? (
                <Stack alignItems='center' justifyContent='center' height='30rem' spacing='1rem'>
                    <Spinner />
                    {isLoadingCreate && <Text>Creating project</Text>}
                </Stack>
            ) : (
                children
            )}
        </Box>
    );
};
