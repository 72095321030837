import { Tab, TabList, TabPanel, TabPanels, Tabs, Text, VStack } from "@chakra-ui/react";

import { CommodityType } from "../../commodities/api/types";
import { CommodityCard } from "../../commodities/components/CommodityCard";
import { NBOCard } from "../../portfolio/components/NBOCard";
import { NBOType } from "../../projects/api/types";

type RightPanelProps = {
    commodities?: CommodityType[];
    pendingNbos?: NBOType[];
    acceptedNbos?: NBOType[];
};

export const RightPanel = ({ commodities, acceptedNbos, pendingNbos }: RightPanelProps) => {
    return (
        <VStack alignSelf='flex-start' w={{ base: "100%", md: "20%" }}>
            <Tabs variant='soft-rounded' colorScheme='green' size='sm' w='full'>
                <TabList fontSize='xs'>
                    <Tab>Commodities</Tab>
                    <Tab>NBO's</Tab>
                </TabList>
                <TabPanels>
                    <TabPanel px='0'>
                        <VStack spacing='1rem' bg='white' borderRadius='xl' p='3'>
                            {commodities && commodities.map(commodity => <CommodityCard key={commodity.id} commodity={commodity} />)}
                        </VStack>
                    </TabPanel>
                    <TabPanel px='0'>
                        <VStack spacing='1.5rem' bg='white' borderRadius='xl' p='3' overflow='auto'>
                            <VStack w='full'>
                                <Text fontWeight='bold'>Accepted NBO's</Text>
                                {acceptedNbos && acceptedNbos.length ? (
                                    acceptedNbos?.map(nbo => <NBOCard key={nbo.id} nbo={nbo} />)
                                ) : (
                                    <Text fontSize='xs' textAlign='center'>
                                        No accepted NBO's yet
                                    </Text>
                                )}
                            </VStack>
                            <VStack w='full'>
                                <Text fontWeight='bold'>Pending NBO's</Text>

                                {pendingNbos && pendingNbos.length ? (
                                    pendingNbos?.map(nbo => <NBOCard key={nbo.id} nbo={nbo} />)
                                ) : (
                                    <Text fontSize='xs' textAlign='center'>
                                        No pending NBO's yet
                                    </Text>
                                )}
                            </VStack>
                        </VStack>
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </VStack>
    );
};
