import { useTheme } from "@chakra-ui/react";
import { ArcElement, BarElement, CategoryScale, ChartData, Chart as ChartJS, ChartOptions, Legend, LinearScale, Title, Tooltip } from "chart.js";
import { Pie } from "react-chartjs-2";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement);

type PieChartType = {
    labels: string[];
    data: number[];
    labelPosition?: "top" | "bottom" | "left" | "right";
    title?: string;
    aspectRatio?: number;
};

export const PieChart = ({ labels, data, labelPosition = "top", title, aspectRatio = 1 }: PieChartType) => {
    const { colors } = useTheme();

    const options: ChartOptions<"pie"> = {
        responsive: true,
        aspectRatio,
        plugins: {
            legend: {
                position: labelPosition
            },
            title: {
                display: Boolean(title),
                text: title
            },
            tooltip: {
                enabled: false
            }
        }
    };

    const dataObj: ChartData<"pie"> = {
        labels,
        datasets: [
            {
                label: "%",
                data,
                backgroundColor: [
                    colors.green[200],
                    colors.green[500],
                    colors.green[600],
                    colors.yellow[200],
                    colors.yellow[500],
                    colors.yellow[600]
                ],
                borderColor: [
                    "rgba(255, 255, 255, 1)",
                    "rgba(255, 255, 255, 1)",
                    "rgba(255, 255, 255, 1)",
                    "rgba(255, 255, 255, 1)",
                    "rgba(255, 255, 255, 1)",
                    "rgba(255, 255, 255, 1)"
                ],
                borderWidth: 4
            }
        ]
    };
    return <Pie options={options} data={dataObj} />;
};
