import { IconType } from ".";

export const ExitIcon = ({ size = 24, color = "black" }: IconType) => (
    <svg width={size} height={size} fill='none' viewBox='0 0 24 24'>
        <path
            stroke={color}
            strokeLinecap='round'
            strokeWidth={1.5}
            d='M9.002 7c.012-2.175.109-3.353.877-4.121C10.758 2 12.172 2 15 2h1c2.829 0 4.243 0 5.122.879C22 3.757 22 5.172 22 8v8c0 2.828 0 4.243-.878 5.121C20.242 22 18.829 22 16 22h-1c-2.828 0-4.242 0-5.121-.879-.768-.768-.865-1.946-.877-4.121'
        />
        <path stroke={color} strokeLinecap='round' strokeLinejoin='round' strokeWidth={1.5} d='M15 12H2m0 0 3.5-3M2 12l3.5 3' />
    </svg>
);
