import { api } from "../../../api/api";

const GEO_TAG = "geography";

export const landingApi = api.enhanceEndpoints({ addTagTypes: [GEO_TAG] }).injectEndpoints({
    endpoints: build => ({
        bookDemo: build.mutation<void, BookDemoInputs>({
            query: args => ({
                body: { ...args },
                method: "POST",
                url: "/core/book-demo"
            })
        })
    })
});

export const { useBookDemoMutation } = landingApi;

export type BookDemoInputs = {
    name: string;
    email: string;
    phone: string;
    company_name: string;
    job_title: string;
    message?: string;
};
