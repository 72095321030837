import { getLocalStorage, removeLocalStorage, setLocalStorage } from "../../../utils/appLocalStorage";

// Auth token storage
const storageKey = "auth-token";

export const saveAuthTokenData = (token: string) => {
    setLocalStorage(storageKey, token);
};

export const removeAuthTokenData = () => {
    removeLocalStorage(storageKey);
};

export const getAuthTokenData = () => {
    return getLocalStorage(storageKey);
};

// Refresh token storage
const refreshKey = "refresh-token";

export const saveRefreshTokenData = (token: string) => {
    setLocalStorage(refreshKey, token);
};

export const removeRefreshTokenData = () => {
    removeLocalStorage(refreshKey);
};

export const getRefreshTokenData = () => {
    return getLocalStorage(refreshKey);
};
