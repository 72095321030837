import { ChevronDownIcon, CloseIcon } from "@chakra-ui/icons";
import { Button, Checkbox, Collapse, HStack, Input, Radio, RadioGroup, Spinner, Text, VStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { SearchBar } from "../../../components/Searchbar";
import { useGetCountriesQuery } from "../../geography/api/geographyApi";
import { useGetEnergyTypesQuery, useGetProjectsStatusChoicesQuery } from "../api/projectsApi";
import { EnergyBadge } from "./EnergyBadge";

export type ToggleFilters = {
    type: boolean;
    countries: boolean;
    status: boolean;
    capacity: boolean;
    investmentType: boolean;
};

type FiltersProps = {
    isOpen: boolean;
    onToggle: () => void;
    toggleFilters: ToggleFilters;
    onToggleFilter: (key: keyof ToggleFilters) => void;
};

type FilterValuesType = {
    searchValue: string;
    energyType: string[];
    country: string[];
    status: string[];
    capacity: string;
    investmentType?: "portfolio" | "project";
};
export const Filters = ({ isOpen, onToggle, toggleFilters, onToggleFilter }: FiltersProps) => {
    const { data: countries, isLoading: loadingCountries } = useGetCountriesQuery();
    const { data: statusChoices, isLoading: loadingStatus } = useGetProjectsStatusChoicesQuery();
    const { data: energyType, isLoading: loadingEnergyTypes } = useGetEnergyTypesQuery();

    const [searchParams, setSearchParams] = useSearchParams();

    const [countryFilter, setCountryFilter] = useState<string>("");

    const getCapacityValue = () => {
        const min = searchParams.get("min_capacity");
        const max = searchParams.get("max_capacity");

        if (min === "0" && max === "1") {
            return "<1";
        }

        if (min === "1000") {
            return ">1000";
        }

        if (min && max) {
            return `${min}-${max}`;
        }
        return "";
    };

    const getInvestmentType = () => {
        const isPortfolio = searchParams.get("is_portfolio");

        if (isPortfolio === "true") {
            return "portfolio";
        }
        return "project";
    };

    const [filtersValues, setFiltersValues] = useState<FilterValuesType>({
        searchValue: "",
        energyType: searchParams.getAll("energy_type"),
        country: searchParams.getAll("country"),
        status: searchParams.getAll("status"),
        capacity: getCapacityValue(),
        investmentType: getInvestmentType()
    });

    const hasFilters = Object.values(filtersValues).some(value => value.length > 0);

    const onFilterApply = () => {
        const params: Record<string, string | number | string[] | number[]> = {};

        if (filtersValues.investmentType) {
            const isPortfolio = filtersValues.investmentType === "portfolio";
            params["is_portfolio"] = String(isPortfolio);
        }

        if (filtersValues.searchValue) {
            params["search"] = filtersValues.searchValue;
        }
        if (filtersValues.energyType.length > 0) {
            params["energy_type"] = filtersValues.energyType;
        }
        if (filtersValues.country.length > 0) {
            params["country"] = filtersValues.country;
        }
        if (filtersValues.status.length > 0) {
            params["status"] = filtersValues.status;
        }
        if (filtersValues.capacity) {
            const capacityValue = filtersValues.capacity;

            switch (capacityValue) {
                case "<1":
                    params["min_capacity"] = 0;
                    params["max_capacity"] = 1;
                    break;
                case "1-10":
                    params["min_capacity"] = 1;
                    params["max_capacity"] = 10;
                    break;
                case "10-100":
                    params["min_capacity"] = 10;
                    params["max_capacity"] = 100;
                    break;
                case "100-1000":
                    params["min_capacity"] = 100;
                    params["max_capacity"] = 1000;
                    break;
                case ">1000":
                    params["min_capacity"] = 1000;
                    break;
            }
        }
        //@ts-ignore
        setSearchParams(params);
        onToggle();
    };

    const getLengthText = (key: string) => {
        if (key === "capacity") {
            if (searchParams.get("min_capacity") || searchParams.get("max_capacity")) {
                return " (1 applied)";
            }
        }

        if (searchParams.getAll(key).length > 0) {
            return ` (${searchParams.getAll(key).length} applied)`;
        }
        return "";
    };

    const setCheckboxFilter = (key: keyof FilterValuesType, valueId: string, isChecked: boolean) => {
        if (typeof filtersValues[key] === "string") return;
        if (isChecked) {
            setFiltersValues({
                ...filtersValues,
                //@ts-ignore
                [key]: [...filtersValues[key], valueId]
            });
        } else {
            setFiltersValues({
                ...filtersValues,
                //@ts-ignore
                [key]: filtersValues[key].filter(id => id !== valueId)
            });
        }
    };

    const onClearFilters = () => {
        setFiltersValues({
            searchValue: "",
            energyType: [],
            country: [],
            status: [],
            capacity: ""
        });
        setCountryFilter("");
    };

    useEffect(() => {
        const hasSearchParams = Array.from(searchParams.entries()).length > 0;
        if (!hasSearchParams && hasFilters && !isOpen) {
            onClearFilters();
        }
    }, [searchParams, hasFilters, isOpen]);

    const options = [
        {
            label: "Energy type" + getLengthText("energy_type"),
            onToggle: () => onToggleFilter("type"),
            isToggled: toggleFilters.type,
            component: (
                <VStack alignItems='flex-start'>
                    {energyType &&
                        energyType.results.map(energy => (
                            <Checkbox
                                size='sm'
                                colorScheme='green'
                                key={energy.id}
                                isChecked={filtersValues.energyType.includes(String(energy.id))}
                                onChange={e => {
                                    setCheckboxFilter("energyType", String(energy.id), e.target.checked);
                                }}>
                                <EnergyBadge energyType={energy.name} />
                            </Checkbox>
                        ))}
                </VStack>
            )
        },
        {
            label: "Countries" + getLengthText("country"),
            onToggle: () => onToggleFilter("countries"),
            isToggled: toggleFilters.countries,
            component: (
                <VStack w='full'>
                    <Input placeholder='Search by country' size='sm' value={countryFilter} onChange={e => setCountryFilter(e.target.value)} />
                    <VStack maxH='15rem' alignItems='flex-start' overflowY='auto' w='full'>
                        {countries
                            ?.filter(item => item.name.toLowerCase().includes(countryFilter.toLowerCase()))
                            .map(country => (
                                <Checkbox
                                    size='sm'
                                    colorScheme='green'
                                    key={country.id}
                                    isChecked={filtersValues.country.includes(String(country.id))}
                                    onChange={e => {
                                        setCheckboxFilter("country", String(country.id), e.target.checked);
                                    }}>
                                    {country.name}
                                </Checkbox>
                            ))}
                    </VStack>
                </VStack>
            )
        },
        {
            label: "Status" + getLengthText("status"),
            onToggle: () => onToggleFilter("status"),
            isToggled: toggleFilters.status,
            component: (
                <VStack alignItems='flex-start'>
                    {statusChoices &&
                        statusChoices.results.map(({ id, name }) => (
                            <Checkbox
                                size='sm'
                                colorScheme='green'
                                key={id}
                                isChecked={filtersValues.status.includes(String(id))}
                                onChange={e => {
                                    setCheckboxFilter("status", String(id), e.target.checked);
                                }}>
                                {name}
                            </Checkbox>
                        ))}
                </VStack>
            )
        },
        {
            label: "Capacity" + getLengthText("capacity"),
            onToggle: () => onToggleFilter("capacity"),
            isToggled: toggleFilters.capacity,
            component: (
                <RadioGroup
                    onChange={value => {
                        setFiltersValues({ ...filtersValues, capacity: value as string });
                    }}
                    value={filtersValues.capacity}>
                    <VStack alignItems='flex-start'>
                        <Radio size='sm' colorScheme='green' value='<1'>
                            <Text fontSize='sm'>Less than 1 MW</Text>
                        </Radio>
                        <Radio size='sm' colorScheme='green' value='1-10'>
                            <Text fontSize='sm'>1 MW - 10 MW</Text>
                        </Radio>
                        <Radio size='sm' colorScheme='green' value='10-100'>
                            <Text fontSize='sm'>10 MW - 100 MW</Text>
                        </Radio>
                        <Radio size='sm' colorScheme='green' value='100-1000'>
                            <Text fontSize='sm'>100 MW - 1 GW</Text>
                        </Radio>
                        <Radio size='sm' colorScheme='green' value='>1000'>
                            <Text fontSize='sm'>More than 1 GW</Text>
                        </Radio>
                    </VStack>
                </RadioGroup>
            )
        },
        {
            label: "Investment type",
            onToggle: () => onToggleFilter("investmentType"),
            isToggled: toggleFilters.investmentType,
            component: (
                <RadioGroup
                    onChange={value => {
                        setFiltersValues({
                            ...filtersValues,
                            investmentType: value as "portfolio" | "project"
                        });
                    }}
                    value={filtersValues.investmentType}>
                    <VStack alignItems='flex-start'>
                        <Radio size='sm' colorScheme='green' value='portfolio'>
                            <Text fontSize='sm'>Portfolio</Text>
                        </Radio>
                        <Radio size='sm' colorScheme='green' value='Project'>
                            <Text fontSize='sm'>Project</Text>
                        </Radio>
                    </VStack>
                </RadioGroup>
            )
        }
    ];

    const isLoading = loadingCountries || loadingStatus || loadingEnergyTypes;

    if (isLoading) {
        return <Spinner />;
    }

    return (
        <VStack bg='white' my='5rem' mr='2rem' p='3' borderRadius='lg' shadow='xl' alignItems='flex-start' spacing='1rem' maxH='80vh'>
            <HStack alignItems='center' justifyContent='space-between' w='full'>
                <HStack spacing='1rem'>
                    <Text fontSize='lg' fontWeight='bold'>
                        Filters
                    </Text>
                    {hasFilters && (
                        <Text fontSize='xs' color='gray.500' textDecoration='underline' cursor='pointer' fontWeight='bold' onClick={onClearFilters}>
                            Clear filters
                        </Text>
                    )}
                </HStack>

                <CloseIcon onClick={onToggle} color='black' boxSize='.6rem' cursor='pointer' />
            </HStack>
            <SearchBar
                placeholder={"Search by project name"}
                value={filtersValues.searchValue}
                onChange={e => setFiltersValues({ ...filtersValues, searchValue: e.target.value })}
                size='sm'
            />
            <VStack spacing='1rem' alignItems='flex-start' w='full' overflowY='auto'>
                {options.map(option => {
                    return (
                        <VStack w='full' alignItems='flex-start' key={option.label}>
                            <HStack justifyContent='space-between' w='full' cursor='pointer' onClick={option.onToggle}>
                                <Text fontWeight='bold' fontSize='sm'>
                                    {option.label}
                                </Text>
                                <ChevronDownIcon />
                            </HStack>
                            <Collapse in={option.isToggled} style={{ width: "100%" }} animateOpacity>
                                {option.component}
                            </Collapse>
                        </VStack>
                    );
                })}
            </VStack>
            <HStack alignSelf='flex-end'>
                <Button size='sm' variant='outline' onClick={onToggle}>
                    Close
                </Button>
                <Button size='sm' onClick={onFilterApply}>
                    Apply
                </Button>
            </HStack>
        </VStack>
    );
};
