import { api } from "../../../api/api";
import { ListUserProfile, UpdateUserProfile, UserType } from "../../users/api/types";

const USER_DETAIL_TAG = "userDetail";
const USER_PROFILE_TAG = "userProfile";

export const usersApi = api.enhanceEndpoints({ addTagTypes: [USER_DETAIL_TAG, USER_PROFILE_TAG] }).injectEndpoints({
    endpoints: build => ({
        getUserDetails: build.query<UserType, void>({
            query: () => ({
                method: "GET",
                url: "users/details/"
            }),
            providesTags: [USER_DETAIL_TAG]
        }),
        updateProfile: build.mutation<UserType, Partial<UpdateUserProfile> | FormData>({
            query: data => ({
                method: "PATCH",
                body: data,
                url: "users/profile/"
            }),
            invalidatesTags: [USER_PROFILE_TAG, USER_DETAIL_TAG]
        }),
        getUserProfile: build.query<ListUserProfile, void>({
            query: () => ({
                method: "GET",
                url: "users/profile/"
            }),
            providesTags: [USER_PROFILE_TAG]
        })
    })
});

export const { useGetUserDetailsQuery, useUpdateProfileMutation, useGetUserProfileQuery } = usersApi;
