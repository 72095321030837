import { IconType } from ".";

export const DownloadIcon = ({ size = 24, color = "black" }: IconType) => (
    <svg width={size} height={size} fill='none'>
        <path
            stroke={color}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth={1.667}
            d='M6.667 14.167 10 17.5m0 0 3.334-3.333M10 17.5V10m6.667 3.952a4.583 4.583 0 0 0-2.917-8.12.516.516 0 0 1-.445-.25 6.25 6.25 0 1 0-9.816 7.58'
        />
    </svg>
);
