import { useEffect } from "react";

import { api } from "../api/api";
import { ws } from "../modules/websocket/api/socketApi";
import { useAppDispatch } from "../store";

export const useWindowEvent = () => {
    const dispatch = useAppDispatch();
    useEffect(() => {
        const handleWindowFocus = () => {
            // Code to handle window focus
        };

        const handleWindowBlur = () => {
            // Code to handle window blur
        };

        const handleWindowClose = () => {
            // Code to handle window close
            ws.close();
            dispatch(api.util.resetApiState());
        };

        window.addEventListener("focus", handleWindowFocus);
        window.addEventListener("blur", handleWindowBlur);
        window.addEventListener("beforeunload", handleWindowClose);

        return () => {
            window.removeEventListener("focus", handleWindowFocus);
            window.removeEventListener("blur", handleWindowBlur);
            window.removeEventListener("beforeunload", handleWindowClose);
        };
    }, [dispatch]);
};
