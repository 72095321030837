import { Box, HStack, Text, VStack } from "@chakra-ui/react";

import brentOil from "../../../assets/images/commodities/brent_oil.jpeg";
import carbon from "../../../assets/images/commodities/carbon_emissions.jpg";
import crude from "../../../assets/images/commodities/crude_oil.jpg";
import gasOil from "../../../assets/images/commodities/gas_oil.jpeg";
import gasoline from "../../../assets/images/commodities/gasoline.jpg";
import heatingOil from "../../../assets/images/commodities/heating_oil.jpg";
import naturalGas from "../../../assets/images/commodities/natural_gas.jpg";
import { CommodityNameType, CommodityType } from "../api/types";

type CommodityCardProps = {
    commodity: CommodityType;
};

export const CommodityCard = ({ commodity }: CommodityCardProps) => {
    const isNegative = commodity.change_percentage.includes("-");
    const isNeutral = commodity.change_percentage === "0.00%";
    const color = isNegative ? "red.500" : "green.500";

    const imagesObj: Record<CommodityNameType, string> = {
        "Brent Oil": brentOil,
        "Carbon Emissions": carbon,
        "Natural Gas": naturalGas,
        "Heating Oil": heatingOil,
        "Crude Oil WTI": crude,
        "Gasoline RBOB": gasoline,
        "London Gas Oil": gasOil
    };

    return (
        <a href={commodity.link} target='blank' style={{ width: "100%" }}>
            <HStack bg='gray.100' borderRadius='xl' p='1' w='full'>
                <Box
                    w='5rem'
                    h='5rem'
                    bg='white'
                    borderRadius='xl'
                    backgroundImage={`url(${imagesObj[commodity.name]})`}
                    backgroundSize='cover'
                    backgroundPosition='center'
                />
                <VStack spacing='1' alignItems='flex-start' flexGrow='1'>
                    <Text fontWeight='bold' fontSize='sm'>
                        {commodity.name}
                    </Text>
                    <Text fontWeight='bold' fontSize='sm' color='gray.500'>
                        {commodity.price}
                    </Text>
                    <HStack w='full' spacing='1' justifyContent='space-between'>
                        <Text fontSize='xs' color={isNeutral ? "gray.500" : color}>
                            {commodity.change}
                        </Text>
                        <Text fontSize='xs' color={isNeutral ? "gray.500" : color} fontWeight='bold'>
                            {commodity.change_percentage}
                        </Text>
                    </HStack>
                </VStack>
            </HStack>
        </a>
    );
};
