import { api } from "../../../api/api";
import { CountryDetails, CountryPreview } from "./types";

const COUNTRY_PREVIEW = "CountryPreview";

export const exploreApi = api.enhanceEndpoints({ addTagTypes: [COUNTRY_PREVIEW] }).injectEndpoints({
    endpoints: build => ({
        getCountryPreview: build.query<CountryPreview, { countryCode: string }>({
            query: ({ countryCode }) => ({
                method: "GET",
                url: "explore/preview/country/" + countryCode
            })
        }),
        getCountryDetails: build.query<CountryDetails, { countryCode: string }>({
            query: ({ countryCode }) => ({
                method: "GET",
                url: "explore/country/" + countryCode
            })
        })
    })
});

export const { useGetCountryPreviewQuery, useGetCountryDetailsQuery } = exploreApi;
