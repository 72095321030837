import { IconType } from ".";

export const LightningIcon = ({ size = 24, color = "#03C988" }: IconType) => (
    <svg width={size} height={size} fill='none'>
        <g clipPath='url(#a)'>
            <path
                stroke={color}
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth={1.5}
                d='M15 1.5 13.5 9l6 2.25L9 22.5l1.5-7.5-6-2.25L15 1.5Z'
            />
        </g>
        <defs>
            <clipPath id='a'>
                <path fill='#fff' d='M0 0h24v24H0z' />
            </clipPath>
        </defs>
    </svg>
);
