import { HStack } from "@chakra-ui/react";

import { SearchBar } from "../../../components/Searchbar";

type HeaderSearchProps = {
    value: string;
    onChange: () => void;
    placeholder?: string;
};

export const HeaderSearch = ({ value, onChange, placeholder }: HeaderSearchProps) => {
    return (
        <HStack justifyContent='space-between' w='full'>
            <SearchBar placeholder={placeholder} value={value} onChange={onChange} />
        </HStack>
    );
};
