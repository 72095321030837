import { ChakraProvider } from "@chakra-ui/react";
import { Provider } from "react-redux";

import { App } from "./App";
import "./App.css";
import store from "./store";
import { theme } from "./theme";

function Root() {
    return (
        <Provider store={store}>
            <ChakraProvider theme={theme}>
                <App />
            </ChakraProvider>
        </Provider>
    );
}

export default Root;
