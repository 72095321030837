import { Box, VStack } from "@chakra-ui/react";

import { FolderIcon } from "../../../assets/icons/folder";

type PortfolioImageProps = {
    images: string[];
    size?: "S" | "M" | "L";
};
export const PortfolioImage = ({ images, size = "S" }: PortfolioImageProps) => {
    const sizeObj: Record<typeof size, string> = {
        S: "10rem",
        M: "20rem",
        L: "30rem"
    };

    return (
        <>
            {images.length ? (
                <VStack flexWrap='wrap' spacing='1' h={sizeObj[size]} w='full'>
                    {images.slice(0, 4).map((image, index) => (
                        <Box
                            key={image}
                            w='100%'
                            bg='green.400'
                            backgroundImage={`url(${image})`}
                            backgroundSize='cover'
                            backgroundPosition='center'
                            borderRadius='lg'
                            flexGrow='1'
                        />
                    ))}
                </VStack>
            ) : (
                <VStack h='10rem' bg='gray.200' w='full' alignItems='center' justifyContent='center' borderRadius='lg'>
                    <FolderIcon />
                </VStack>
            )}
        </>
    );
};
