import { type PayloadAction, createSlice } from "@reduxjs/toolkit";

import { type RootState } from "../../../store/state";

type ConnectionStatus = "connected" | "disconnected" | "reconnecting" | "error";

interface SocketState {
    isLoading: boolean;
    nboCreated: boolean;
    connectionStatus: ConnectionStatus;
}

export const initialState: SocketState = {
    isLoading: false,
    nboCreated: false,
    connectionStatus: "disconnected"
};

const socketSlice = createSlice({
    name: "socket",
    initialState,
    reducers: {
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setNBOCreated: (state, action: PayloadAction<boolean>) => {
            state.nboCreated = action.payload;
        },
        setConnectionStatus: (state, action: PayloadAction<ConnectionStatus>) => {
            state.connectionStatus = action.payload;
            state.isLoading = false;
        },
        setConnectionLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        }
    }
});

// Actions generated from the slice
export const { setIsLoading, setNBOCreated, setConnectionStatus, setConnectionLoading } = socketSlice.actions;

// export user selector to get the slice in any component
export const socketSelector = (state: RootState) => state.socket;
// export The reducer
const socketReducer = socketSlice.reducer;
export default socketReducer;
