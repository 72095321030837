import { api } from "../../../api/api";
import { CountryType } from "./types";

const GEO_TAG = "geography";

export const geographyApi = api.enhanceEndpoints({ addTagTypes: [GEO_TAG] }).injectEndpoints({
    endpoints: build => ({
        getCountries: build.query<CountryType[], void>({
            query: () => ({
                method: "GET",
                url: "geography/"
            }),
            providesTags: [GEO_TAG]
        })
    })
});

export const { useGetCountriesQuery } = geographyApi;
