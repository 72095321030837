export const CapacityIcon = () => (
    <svg width={21} height={21} fill='none'>
        <path
            fill='#2F6F2F'
            stroke='#2F6F2F'
            strokeWidth={0.25}
            d='m19.884 7.717-4.48-4.479a.395.395 0 0 0-.655.155l-.925 2.776L9.113 1.79h.693a.396.396 0 1 0 0-.792h-1.68a.396.396 0 0 0-.396.396v1.68a.396.396 0 1 0 .792 0v-.753l5.213 4.845a.395.395 0 0 0 .645-.164l.918-2.752 4.026 4.026a.395.395 0 1 0 .56-.56ZM7.334 6.541c-3.047 0-6.326.618-6.333 1.975H1v9.506c0 1.36 3.284 1.98 6.334 1.98 3.051 0 6.335-.62 6.335-1.98V8.666h-.022c.009-.048.022-.095.022-.145 0-1.36-3.284-1.98-6.335-1.98Zm5.543 9.105c0 .355-1.885 1.188-5.543 1.188-3.657 0-5.542-.832-5.542-1.188v-1.351c1.156.65 3.404.956 5.542.956 2.139 0 4.387-.306 5.543-.956v1.351Zm0-2.375c0 .355-1.885 1.188-5.543 1.188-3.657 0-5.542-.833-5.542-1.188V11.92c1.156.65 3.404.956 5.542.956 2.139 0 4.387-.305 5.543-.956v1.352Zm0-2.375c0 .355-1.885 1.188-5.543 1.188-3.657 0-5.542-.833-5.542-1.188V9.544c1.156.65 3.404.956 5.542.956 2.139 0 4.387-.306 5.543-.956v1.352ZM7.334 7.333c3.658 0 5.543.833 5.543 1.188s-1.885 1.187-5.543 1.187c-3.657 0-5.542-.832-5.542-1.187 0-.355 1.885-1.188 5.542-1.188Zm0 11.876c-3.657 0-5.542-.832-5.542-1.187V16.67c1.156.65 3.404.956 5.542.956 2.139 0 4.387-.306 5.543-.956v1.352c0 .355-1.885 1.187-5.543 1.187Z'
        />
    </svg>
);
