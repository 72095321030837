import { MultiSelect, Option, SelectionVisibilityMode } from "chakra-multiselect";
import { useState } from "react";

import { useGetEnergyTypesQuery } from "../modules/projects/api/projectsApi";

export const useEnergyTypeSelect = (defaultValue?: number) => {
    const { data } = useGetEnergyTypesQuery();
    const [value, setValue] = useState<string | undefined>(defaultValue ? String(defaultValue) : "");

    const options = data?.results.map(({ id, name }) => ({ label: name, value: String(id) }));
    const EnergyTypeSelect = (
        <MultiSelect
            placeholder='Select the energy type'
            options={options || []}
            value={value}
            onChange={val => {
                // @ts-ignore
                setValue(val);
            }}
            size='md'
            single
            filterFn={(option, query) => {
                const searchValue = query as string;
                return option.filter(o => o.label.toLowerCase().includes(searchValue.toLowerCase()));
            }}
        />
    );
    return { EnergyTypeSelect, value: Number(value) };
};

export const useMultipleEnergyTypeSelect = (defaultValue?: Option[]) => {
    const { data } = useGetEnergyTypesQuery();
    const options: Option[] = data ? data.results.map(({ id, name }) => ({ label: name, value: String(id) })) : [];

    const [value, setValue] = useState<Option[]>(defaultValue || []);

    const EnergyTypeSelect = (
        <MultiSelect
            _placeholder={{ color: "gray.400" }}
            placeholder='Select the energy type'
            options={options}
            value={value}
            onChange={val => {
                // @ts-ignore
                setValue(val);
            }}
            size='md'
            single={false}
            filterFn={(option, query) => {
                const searchValue = query as string;
                return option.filter(o => o.label.toLowerCase().includes(searchValue.toLowerCase()));
            }}
            selectionVisibleIn={SelectionVisibilityMode.Both}
            w='full'
        />
    );

    return { EnergyTypeSelect, values: value.map(({ value }) => Number(value)) };
};
