import { Button, FormControl, FormErrorMessage, FormLabel, Input, VStack } from "@chakra-ui/react";
import { useEffect } from "react";

import { useCountrySelect } from "../../../../hooks/useCountrySelect";
import { ClearErrorsType, ErrorsType, SetErrorType, SetValueType } from "../../pages/ProjectCreate";

type GeographyProps = {
    setCurrentIndex: (index: number) => void;
    setValue: SetValueType;
    setError: SetErrorType;
    errors: ErrorsType;
    clearErrors: ClearErrorsType;
    country: number;
    location: string;
};

export const Geography = ({ setCurrentIndex, setValue, setError, errors, clearErrors, country, location }: GeographyProps) => {
    const { CountrySelect, value: countryId } = useCountrySelect(country, "Select the country (e.g., Italy)");

    useEffect(() => {
        if (countryId) {
            setValue("country", countryId);
        }
    }, [countryId, setValue]);

    const onValidate = () => {
        clearErrors();
        if (!location) {
            setError("location", { message: "Location is required" });
            return;
        }
        if (!country) {
            setError("country", { message: "Country is required" });
            return;
        }

        setCurrentIndex(3);
    };

    return (
        <VStack spacing='1rem'>
            <FormControl isInvalid={Boolean(errors.location)}>
                <FormLabel htmlFor='location'>Location</FormLabel>
                <Input
                    id='location'
                    placeholder='Enter specific location (e.g., Lecce)'
                    onChange={e => setValue("location", e.target.value)}
                    value={location}
                />
                <FormErrorMessage>{errors.location && errors.location.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={Boolean(errors.country)}>
                <FormLabel htmlFor='country'>Country</FormLabel>
                {CountrySelect}
                <FormErrorMessage>{errors.country && errors.country.message}</FormErrorMessage>
            </FormControl>
            <Button type='submit' onClick={onValidate} w='full' mt='2rem'>
                Next
            </Button>
        </VStack>
    );
};
