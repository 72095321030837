import { Box, HStack, Image, Text, VStack } from "@chakra-ui/react";
import { Link } from "react-router-dom";

import { getCountryFlag } from "../../../utils/flags";
import { ProjectSuggestions } from "../api/types";

export const ProjectSuggestionsRow = ({ project }: { project: ProjectSuggestions }) => {
    return (
        <Link to={"/projects/" + project.slug} style={{ width: "100%" }}>
            <HStack borderWidth='1px' shadow='sm' borderRadius='md' p='2' position='relative'>
                <Image
                    w='1rem'
                    src={getCountryFlag(project.location.country_code)}
                    alt={project.energy_type.name}
                    borderRadius='lg'
                    position='absolute'
                    top='.5rem'
                    right='.5rem'
                />
                <Box bgImage={`url(${project.energy_type.image})`} w='4rem' h='4rem' borderRadius='md' bgSize='cover' bgPosition='center' />
                <VStack alignItems='flex-start'>
                    <Text fontWeight='bold' fontSize='md'>
                        {project.name}
                    </Text>
                    <HStack alignItems='center'>
                        <Text fontSize='sm'>
                            {project.location.location}, {project.location.country_name}
                        </Text>
                    </HStack>
                </VStack>
            </HStack>
        </Link>
    );
};
