import { Navigate, createBrowserRouter } from "react-router-dom";

import { HomeLayout } from "../components/HomeLayout";
import { EditProjectProvider } from "../context/EditProjectContext";
import { EmailVerification } from "../modules/auth/pages/EmailVerification";
import { Login } from "../modules/auth/pages/Login";
import { Register } from "../modules/auth/pages/Register";
import { CountryDetails } from "../modules/explore/screen/CountryDetails";
import { Explore } from "../modules/explore/screen/Explore";
import { Dashboard } from "../modules/home/pages/Dashboard";
import { BookDemo } from "../modules/landing/pages/BookDemo";
import { Landing } from "../modules/landing/pages/Landing";
import { TermsConditions } from "../modules/landing/pages/TermsConditions";
import { News } from "../modules/news/pages/News";
import { Portfolio } from "../modules/portfolio/pages/Portfolio";
import { Profile } from "../modules/profile/pages/Profile";
import { MyProjects } from "../modules/projects/pages/MyProjects";
import { Opportunities } from "../modules/projects/pages/Opportunities";
import { PortfolioDetails } from "../modules/projects/pages/PortfolioDetails";
import { ProjectCreate } from "../modules/projects/pages/ProjectCreate";
import { ProjectDetails } from "../modules/projects/pages/ProjectDetails";
import { UsersList } from "../modules/users/pages/UsersList";

export const router = createBrowserRouter([
    {
        path: "/",
        element: <Landing />
    },
    {
        path: "/login",
        element: <Login />
    },
    {
        path: "/register/:role",
        element: <Navigate to='/register' replace />
    },
    {
        path: "/register",
        element: <Register />
    },
    {
        path: "/book-demo",
        element: <BookDemo />
    },
    {
        path: "/terms-conditions",
        element: <TermsConditions />
    },
    { path: "/email-verification", element: <EmailVerification /> },
    {
        path: "/",
        element: (
            <EditProjectProvider>
                <HomeLayout />
            </EditProjectProvider>
        ),
        children: [
            { path: "/dashboard", element: <Dashboard /> },
            { path: "/my-projects", element: <MyProjects /> },
            { path: "/projects", element: <Opportunities /> },
            { path: "/projects/portfolios", element: <Opportunities /> },
            { path: "/projects/:slug", element: <ProjectDetails /> },
            { path: "/users", element: <UsersList /> },
            { path: "/users/:dialogId", element: <UsersList /> },
            { path: "/project/create", element: <ProjectCreate /> },
            { path: "/project/add/:portfolioId", element: <ProjectCreate /> },
            { path: "/portfolio", element: <Portfolio /> },
            { path: "/profile", element: <Profile /> },
            { path: "/portfolio/:portfolioId", element: <PortfolioDetails /> },
            { path: "/news", element: <News /> },
            { path: "/explore", element: <Explore /> },
            { path: "/explore/:countryCode", element: <CountryDetails /> }
        ]
    }
]);
