export const YieldIcon = () => (
    <svg width={21} height={21} fill='none'>
        <path
            fill='#2F6F2F'
            stroke='#2F6F2F'
            strokeWidth={0.5}
            d='M18.987 20H2.035A1.04 1.04 0 0 1 1 18.952c0-.58.462-1.048 1.035-1.048h16.93A1.04 1.04 0 0 1 20 18.952c0 .58-.462 1.048-1.013 1.048ZM2.035 18.372a.596.596 0 0 0-.595.602c0 .335.265.602.595.602h16.93c.33 0 .595-.267.595-.602a.596.596 0 0 0-.595-.602H2.035ZM5.491 17.324h-.594a1.461 1.461 0 0 1-1.453-1.472v-6.78c0-.802.638-1.471 1.453-1.471h.594c.793 0 1.453.647 1.453 1.472v6.801c-.022.803-.66 1.45-1.453 1.45Zm-.594-9.255c-.55 0-1.013.446-1.013 1.026v6.802c0 .557.44 1.025 1.013 1.025h.594c.55 0 1.013-.445 1.013-1.025V9.073c0-.558-.44-1.026-1.013-1.026h-.594v.022ZM10.885 17.324h-.594c-.793 0-1.431-.647-1.431-1.472V6.085c0-.803.638-1.45 1.43-1.45h.595c.793 0 1.431.647 1.431 1.45v9.767a1.416 1.416 0 0 1-1.43 1.472Zm-.572-12.243c-.55 0-.99.446-.99 1.004v9.767c0 .558.44 1.026.99 1.026h.594c.55 0 .991-.446.991-1.026V6.085a.993.993 0 0 0-.99-1.004h-.595ZM16.301 17.324h-.594a1.461 1.461 0 0 1-1.453-1.472V2.45c0-.803.638-1.45 1.453-1.45h.594c.793 0 1.454.647 1.454 1.45v13.402c0 .825-.661 1.472-1.454 1.472Zm-.594-15.878c-.55 0-1.013.446-1.013 1.004v13.402c0 .558.44 1.026 1.013 1.026h.594c.55 0 1.013-.446 1.013-1.026V2.45a.998.998 0 0 0-1.013-1.004h-.594Z'
        />
        <path fill='#2F6F2F' d='m11.194 1.223 2.488.29-1.475 2.03-1.013-2.32Z' />
        <path fill='#2F6F2F' d='M12.184 3.765 11.018 1.09l2.862.312-1.696 2.364Zm-.792-2.408.859 1.962 1.254-1.74-2.113-.222Z' />
        <path fill='#2F6F2F' d='M12.488 1.669 2.078 6.332l.277.632 10.409-4.663-.276-.632Z' />
        <path fill='#2F6F2F' d='m2.299 7.11-.352-.847 10.612-4.75.374.847L2.299 7.11Zm-.066-.736.176.424 10.216-4.571-.177-.424L2.233 6.374Z' />
    </svg>
);
