import { IconType } from "../icons";

export const WaveIcon = ({ size = 24, color = "#03C988" }: IconType) => (
    <svg width={size} height={size + 1} fill='none'>
        <g stroke={color} strokeLinecap='round' strokeLinejoin='round' strokeWidth={1.5} clipPath='url(#a)'>
            <path d='M3.75 17.6c6.75-5.595 9.75 5.295 16.5-.301M3.75 12.35c6.75-5.595 9.75 5.295 16.5-.301M3.75 7.1c6.75-5.595 9.75 5.295 16.5-.301' />
        </g>
        <defs>
            <clipPath id='a'>
                <path fill='#fff' d='M0 .2h24v24H0z' />
            </clipPath>
        </defs>
    </svg>
);
