import React, { ReactNode } from "react";

type LayoutProps = {
    children: ReactNode;
};

export const Layout = ({ children }: LayoutProps) => {
    return (
        <div>
            {/* Add your layout content here */}
            {children}
        </div>
    );
};
