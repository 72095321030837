import { type Action, type ThunkAction, configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

import { api } from "../api/api";
import { config } from "../config";
import rootReducer, { type RootState } from "./state";

const store = configureStore({
    reducer: rootReducer,
    devTools: config.isDevEnv,
    middleware: getDefaultMiddleWare =>
        getDefaultMiddleWare({
            serializableCheck: false
        }).concat(api.middleware)
});

export type AppStore = typeof store;
export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export default store;
