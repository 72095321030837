import { type PayloadAction, createSlice } from "@reduxjs/toolkit";

import { type RootState } from "../../../store/state";
import { removeAuthTokenData, removeRefreshTokenData, saveAuthTokenData } from "../services/authStorage";

interface AuthState {
    authToken: string;
    userId: number | null;
}

export const initialState: AuthState = {
    authToken: "",
    userId: null
};

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setUserId: (state, action: PayloadAction<number | null>) => {
            state.userId = action.payload;
        },
        setToken: (state, action: PayloadAction<string | null>) => {
            const token = action.payload;

            if (token) {
                state.authToken = token;
                saveAuthTokenData(token);
            } else {
                removeAuthTokenData();
                removeRefreshTokenData();
                state.authToken = "";
            }
        }
    }
});

// Actions generated from the slice
export const { setToken, setUserId } = authSlice.actions;

// export user selector to get the slice in any component
export const authSelector = (state: RootState) => state.auth;
// export The reducer
const authReducer = authSlice.reducer;
export default authReducer;
