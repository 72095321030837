export type PayloadTypeMap = Readonly<{
    "auth-token": string;
    "refresh-token": string;
}>;

// Function to set a value in localStorage
export function setLocalStorage<T extends keyof PayloadTypeMap>(key: T, value: any): void {
    localStorage.setItem(key, JSON.stringify(value));
}

// Function to remove a value from localStorage
export function removeLocalStorage<T extends keyof PayloadTypeMap>(key: T): void {
    localStorage.removeItem(key);
}

// Function to get a value from localStorage
export function getLocalStorage<T extends keyof PayloadTypeMap>(key: T): any {
    const value = localStorage.getItem(key);
    return value ? JSON.parse(value) : null;
}
