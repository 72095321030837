import { useToast } from "@chakra-ui/react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { chatSelector, setGoToDialog } from "../modules/chat/data/chatSlice";
import { setNBOCreated, socketSelector } from "../modules/websocket/data/socketSlice";

export const useSocketDisplayMessages = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const toast = useToast();

    const { goToDialog } = useSelector(chatSelector);
    const { nboCreated } = useSelector(socketSelector);

    // Navigate to the dialog page when the investor wants to chat
    useEffect(() => {
        if (goToDialog) {
            navigate(`/users/${goToDialog}`);
            dispatch(setGoToDialog(null));
        }
    }, [goToDialog, navigate, dispatch]);

    useEffect(() => {
        if (nboCreated) {
            dispatch(setNBOCreated(false));
            toast({
                title: "NBO created",
                description: "You have successfully created an NBO.",
                status: "success",
                duration: 3000
            });
        }
    }, [nboCreated, dispatch, toast]);
};
