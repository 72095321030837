import { Box, Button, HStack, Slide, Tab, TabList, TabPanel, TabPanels, Tabs, Text, useDisclosure } from "@chakra-ui/react";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";

import { FilterIcon } from "../../../assets/icons/filter";
import { GridCards } from "../../../components/GridCards";
import { PageLayout } from "../../../components/PageLayout";
import { useGetPortfoliosListQuery, useGetProjectsListQuery } from "../api/projectsApi";
import { Filters, ToggleFilters } from "../components/Filters";
import { PortfolioCard } from "../components/PortfolioCard";
import { ProjectCard } from "../components/ProjectCard";

export const Opportunities = () => {
    const { isOpen, onToggle } = useDisclosure();

    const [toggleFilters, setToggleFilters] = useState<ToggleFilters>({
        type: true,
        countries: false,
        status: false,
        capacity: false,
        investmentType: false
    });

    const [searchParams, setSearchParams] = useSearchParams();

    const { data: projects, isLoading } = useGetProjectsListQuery({
        search: searchParams.get("search") || undefined,
        country: searchParams.get("country") || undefined,
        status: searchParams.get("status") || undefined,
        min_capacity: searchParams.get("min_capacity") || undefined,
        max_capacity: searchParams.get("max_capacity") || undefined,
        energy_type: searchParams.get("energy_type") || undefined,
        is_portfolio: searchParams.get("is_portfolio") || undefined
    });

    const { data: portfolios, isLoading: loadingPortfolios } = useGetPortfoliosListQuery(undefined);

    const onToggleFilter = (filter: keyof ToggleFilters) => {
        setToggleFilters(prev => ({ ...prev, [filter]: !prev[filter] }));
    };

    const hasParams = Array.from(searchParams.entries()).length > 0;

    return (
        <PageLayout
            title='Opportunities'
            isLoading={isLoading || loadingPortfolios}
            rightAction={
                <HStack spacing='1rem'>
                    {hasParams && (
                        <Text color='red.600' fontWeight='bold' onClick={() => setSearchParams({})} cursor='pointer' fontSize='xs'>
                            Clear filters
                        </Text>
                    )}
                    <Button onClick={onToggle} colorScheme='black' variant='link'>
                        <FilterIcon />
                        <Text fontSize='md' ml='1'>
                            Filters
                        </Text>
                    </Button>
                </HStack>
            }>
            <Box>
                <Tabs variant='soft-rounded' colorScheme='green' size='sm'>
                    <TabList position='sticky'>
                        <Tab>Projects</Tab>
                        <Tab>Portfolios</Tab>
                    </TabList>
                    <TabPanels h='full' flexGrow='1' overflowY='auto' bg='white' mt='1rem' borderRadius='lg'>
                        <TabPanel>
                            {projects && projects.results.length ? (
                                <GridCards>{projects?.results.map(project => <ProjectCard key={project.id} project={project} />)}</GridCards>
                            ) : (
                                <Box>
                                    <Text>No projects available</Text>
                                </Box>
                            )}
                        </TabPanel>
                        <TabPanel>
                            {portfolios && portfolios.results.length ? (
                                <GridCards>
                                    {portfolios?.results.map(portfolio => <PortfolioCard key={portfolio.id} portfolio={portfolio} />)}
                                </GridCards>
                            ) : (
                                <Box>
                                    <Text>No portfolios available</Text>
                                </Box>
                            )}
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Box>

            <Slide direction='right' in={isOpen} style={{ zIndex: 10, width: "20rem" }}>
                <Filters
                    onToggle={onToggle}
                    onToggleFilter={onToggleFilter}
                    toggleFilters={toggleFilters}
                    // @ts-ignore
                    isOpen={isOpen}
                />
            </Slide>
        </PageLayout>
    );
};
