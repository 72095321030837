import { CheckCircleIcon } from "@chakra-ui/icons";
import { Box, Button, Circle, Flex, HStack, Heading, Image, SimpleGrid, Stack, Text, VStack } from "@chakra-ui/react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import agile from "../../../assets/images/landing/agile.svg";
import bidDecor from "../../../assets/images/landing/big-decor.svg";
import decor from "../../../assets/images/landing/decor-img.svg";
import hero from "../../../assets/images/landing/hero-image.jpg";
import innovative from "../../../assets/images/landing/innovative.svg";
import intuitive from "../../../assets/images/landing/intuitive.svg";
import safe from "../../../assets/images/landing/safe.svg";
import solar from "../../../assets/images/landing/solar.jpg";
import startImage from "../../../assets/images/landing/start-image.jpg";
import transparent from "../../../assets/images/landing/transparent.svg";
import trust from "../../../assets/images/landing/trust.svg";
import demo from "../../../assets/video/final_cursor.mp4";
import { EnergyBadge } from "../../projects/components/EnergyBadge";
import { Footer } from "../components/Footer";
import { Header } from "../components/Header";

const maxWidth = "70rem";

const options = [
    {
        imageSrc: intuitive,
        title: "Intuitive",
        description: "For end investors with retail design. Simple and intuitive as investing should be."
    },
    {
        imageSrc: transparent,
        title: "Transparent",
        description: "No intermediaries! We are giving control to both parties to seal the deal in a more efficient way."
    },
    {
        imageSrc: trust,
        title: "Trustworthy",
        description: "A solid backend and trustworthy data for sustainable investing."
    },
    {
        imageSrc: innovative,
        title: "Innovative",
        description: "A new way to buy and sell renewable projects allowing you to stay competitive."
    },
    {
        imageSrc: safe,
        title: "Safe",
        description:
            "All parties are required to undergo a KYC (Know Your Customer) process to comply with industry regulations and prevent potential legal issues."
    },
    {
        imageSrc: agile,
        title: "Agile",
        description:
            "Respond quickly to market changes, trends, and demands, maintaining a flexible and agile stance in a dynamic business environment."
    }
];

export const Landing = () => {
    return (
        <Box overflowY='hidden'>
            {process.env.NODE_ENV === "production" && (
                <Helmet>
                    <script>
                        window.aichatbotApiKey="b3fe3eee-81ea-493d-8417-76d25ac186fc";
                        window.aichatbotProviderId="f9e9c5e4-6d1a-4b8c-8d3f-3f9e9c5e46d1";
                    </script>
                    <script src='https://script.chatlab.com/aichatbot.js' id='b3fe3eee-81ea-493d-8417-76d25ac186fc' defer></script>
                </Helmet>
            )}
            <Header />
            <Flex fontSize='xl' position='relative' flexDir='column' gap={{ base: "6rem", md: "17rem" }} mt='5rem'>
                <MaxWidthContainer>
                    <Flex alignItems='center' gap='5rem' justifyContent='space-between' mx='4'>
                        <Flex flexDirection='column' gap='1.2rem' maxW='30rem' alignItems='flex-start'>
                            <GradientText fontSize='3xl'>Empowering Sustainable Growth</GradientText>
                            <Text fontSize='3xl'>Where Developers meet Investors for the Future of Renewable Excellence.</Text>
                            <Text fontSize='1.1rem'>
                                A SaaS platform connecting developers and investors effortlessly. We offer a user-friendly system that will turn
                                aspirations into the future of renewable innovation.
                            </Text>
                            <Button mt='8' as={Link} to='book-demo'>
                                Book a demo
                            </Button>
                        </Flex>
                        <Flex display={{ base: "none", md: "flex" }}>
                            <Box position='relative'>
                                <Image src={hero} alt='hero' w='30rem' borderLeftRadius='3xl' />
                                <Image src={decor} alt='decor' position='absolute' bottom='-10' right='-10' w='full' />
                            </Box>
                        </Flex>
                    </Flex>
                </MaxWidthContainer>

                <Box position='relative' id='solution'>
                    <Box
                        backgroundImage={bidDecor}
                        position='absolute'
                        top='0'
                        left='0'
                        w='100%'
                        bottom='0'
                        backgroundRepeat='no-repeat'
                        backgroundPosition='center center'
                        backgroundSize='auto 100%'
                        zIndex='-1'
                        display={{ base: "none", md: "block" }}
                    />
                    <MaxWidthContainer>
                        <Flex
                            gap={{ base: "5rem", md: "2rem" }}
                            py={{ base: "3rem", md: "5rem" }}
                            flexDirection={{ base: "column-reverse", md: "row" }}
                            alignItems='center'
                            bg={{ base: "green.500", md: "transparent" }}
                            mx='4'
                            borderRadius={{ base: "xl", md: "none" }}>
                            <Flex w={{ base: "100%", md: "50%" }} justifyContent='center' px='6'>
                                <Box w='23rem' h='30rem' bgColor='white' borderRadius='xl' boxShadow='xl'>
                                    <Image src={solar} alt='solar' w='full' h='50%' objectFit='cover' borderTopRadius='xl' />
                                    <VStack w='full' gap='1rem' p='.5rem' px='.8rem' justifyContent='space-between'>
                                        <HStack w='full' justifyContent='space-between'>
                                            <EnergyBadge energyType='Solar' fontSize='sm' />
                                            <Box py='.5' px='2' borderRadius='xl' bg='gray.200' borderWidth='1px' borderColor='gray.700'>
                                                <Text fontSize='sm' color='gray.700'>
                                                    RtB
                                                </Text>
                                            </Box>
                                        </HStack>
                                        <VStack w='full' alignItems='flex-start' gap='.5rem'>
                                            <Text fontWeight='bold'>Apollo</Text>
                                            <Text fontSize='md'>
                                                <span style={{ fontWeight: "bold" }}>Type:</span> Solar
                                            </Text>
                                            <Text fontSize='md'>
                                                <span style={{ fontWeight: "bold" }}>Capacity:</span> 200 MW
                                            </Text>
                                            <Text fontSize='md'>
                                                <span style={{ fontWeight: "bold" }}>Yield:</span> 3 %
                                            </Text>
                                        </VStack>
                                        <Button w='full'>View project</Button>
                                    </VStack>
                                </Box>
                            </Flex>
                            <Flex color='white' flexDir='column' w={{ base: "100%", md: "50%" }} justifyContent='center' gap='2rem' px='6'>
                                <Heading fontSize='3xl' fontWeight='500'>
                                    CleanHarbour has reshaped how developers connect with end-investors, initiating a groundbreaking transformation.
                                </Heading>
                                <Text color='white' fontSize='md'>
                                    Our solution streamlines the entire user experience, making it straightforward, captivating, and highly efficient!
                                </Text>
                                <Flex flexDir='column' gap='.5rem'>
                                    <HStack alignItems='center'>
                                        <CheckCircleIcon color='white' />
                                        <Text>Engaging User experience</Text>
                                    </HStack>
                                    <HStack alignItems='center'>
                                        <CheckCircleIcon color='white' />
                                        <Text>Agile & Sustainable investing</Text>
                                    </HStack>
                                    <HStack alignItems='center'>
                                        <CheckCircleIcon color='white' />
                                        <Text>Enhancing investment holdings</Text>
                                    </HStack>
                                </Flex>
                                <Button
                                    as={Link}
                                    to='book-demo'
                                    alignSelf='flex-start'
                                    bg='white'
                                    textColor='green.400'
                                    _hover={{ background: "green.200", textColor: "white" }}>
                                    Book a demo
                                </Button>
                            </Flex>
                        </Flex>
                    </MaxWidthContainer>
                </Box>
                <MaxWidthContainer>
                    <HStack justifyContent='center' mb='10' id='essence'>
                        <GradientText>Our essence</GradientText>
                    </HStack>
                    <SimpleGrid columns={[1, 2, 3]} mb='12' mx='4' gap='2rem'>
                        {options.map((option, index) => (
                            <VStack
                                overflow='hidden'
                                key={index}
                                gap='1rem'
                                boxShadow='#34CC82 0px 0px .4rem'
                                px='12'
                                py='8'
                                borderRadius='xl'
                                color='gray.500'>
                                <Image src={option.imageSrc} alt='intuitive' w='full' />
                                <Text fontWeight='bold'>{option.title}</Text>
                                <Text textAlign='center'>{option.description}</Text>
                            </VStack>
                        ))}
                    </SimpleGrid>
                </MaxWidthContainer>
                <MaxWidthContainer>
                    <Flex w='full' flexDir={{ base: "column", md: "row" }} alignItems='center' gap='2rem' p='4'>
                        <VStack gap='1rem' w={{ base: "100%", md: "50%" }} alignItems='flex-start' color='gray.600'>
                            <Stack mb='1rem' id='integration'>
                                <GradientText>A White Label from the start</GradientText>
                            </Stack>
                            <HStack alignItems='flex-start' gap='.8rem'>
                                <CheckCircleIcon color='#34CC82' mt='2' />
                                <VStack w='full' alignItems='flex-start'>
                                    <Text fontWeight='bold'>Easy integration</Text>
                                    <Text>To improve user experience and enhance the simplicity of the platform.</Text>
                                </VStack>
                            </HStack>
                            <HStack alignItems='flex-start' gap='.8rem'>
                                <CheckCircleIcon color='#34CC82' mt='2' />
                                <VStack w='full' alignItems='flex-start'>
                                    <Text fontWeight='bold'>API</Text>
                                    <Text>Capability of having you using your own front-end and plug into our API.</Text>
                                </VStack>
                            </HStack>
                            <HStack alignItems='flex-start' gap='.8rem'>
                                <CheckCircleIcon color='#34CC82' mt='2' />
                                <VStack w='full' alignItems='flex-start'>
                                    <Text fontWeight='bold'>Connect</Text>
                                    <Text>
                                        Ultimate hub for connecting with developers and investors, offering unparalleled opportunities for growth and
                                        collaboration.
                                    </Text>
                                </VStack>
                            </HStack>
                        </VStack>
                        <VStack position='relative'>
                            <Image
                                src={startImage}
                                alt='start'
                                w={{ base: "100%", md: "50%" }}
                                borderRadius='xl'
                                maxW={{ base: "20rem", md: "none" }}
                            />
                            <Circle
                                size='12rem'
                                position='absolute'
                                bgGradient='linear(45deg, ,#0fabd1, #34CC82)'
                                filter='blur(20px)'
                                top={{ base: "-200", md: "5" }}
                                right={{ base: "150", md: "-75" }}
                                zIndex='-1'
                            />
                            <Circle
                                size='25rem'
                                position='absolute'
                                bgGradient='linear(90deg, ,#0fabd1, #34CC82)'
                                filter='blur(20px)'
                                top='10'
                                left='20'
                                zIndex='-1'
                            />
                        </VStack>
                    </Flex>
                </MaxWidthContainer>
                <MaxWidthContainer>
                    <VStack alignItems='flex-start' color='gray.600' w='full' p='4'>
                        <Stack mb='1.4rem' id='showcase' alignItems='center' w='full'>
                            <GradientText>Take a peek at our product lineup</GradientText>
                        </Stack>
                        <video
                            autoPlay
                            width='100%'
                            height='auto'
                            muted
                            loop
                            style={{ borderRadius: 20, boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px" }}>
                            <source src={demo} type='video/mp4' />
                            Your browser does not support the video tag.
                        </video>
                    </VStack>
                </MaxWidthContainer>
                <Footer />
            </Flex>
        </Box>
    );
};

export const MaxWidthContainer = ({ children }: { children: React.ReactNode }) => {
    return (
        <Box maxW={maxWidth} mx='auto'>
            {children}
        </Box>
    );
};

export const GradientText = ({ children, fontSize = "4xl" }: { children: React.ReactNode; fontSize?: "2xl" | "3xl" | "4xl" }) => {
    return (
        <Text bgGradient='linear(-45deg,#0fabd1, #34CC82)' bgClip='text' fontSize={{ base: "3xl", md: fontSize }} fontWeight='bold'>
            {children}
        </Text>
    );
};
