import { IconType } from ".";

export const DeleteIcon = ({ size = 24, color = "black" }: IconType) => (
    <svg width={size} height={size} fill='none' viewBox='0 0 24 24'>
        <path stroke={color} strokeLinecap='round' strokeLinejoin='round' strokeWidth={1.5} d='M3 6.99a26.38 26.38 0 0 1 18 0' />
        <path
            stroke={color}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth={1.5}
            d='M8.01 5.72a4 4 0 1 1 8 0M12 13v5M19 9.99l-.67 8a4.44 4.44 0 0 1-4.33 4h-4a4.44 4.44 0 0 1-4.33-4l-.67-8'
        />
    </svg>
);
