import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from "@chakra-ui/react";

type CustomModalProps = {
    title: string;
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
    onCancel?: () => void;
    children: JSX.Element;
    confirmText?: string;
    cancelText?: string;
    isLoading?: boolean;
};
export const CustomModal = ({ children, title, isOpen, onConfirm, onClose, confirmText, cancelText, onCancel, isLoading }: CustomModalProps) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{title}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>{children}</ModalBody>

                <ModalFooter>
                    <Button colorScheme='red' mr={3} onClick={onCancel || onClose} size='sm' variant='outline'>
                        {cancelText || "Close"}
                    </Button>
                    <Button isLoading={isLoading} size='sm' onClick={onConfirm}>
                        {confirmText || "OK"}
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
