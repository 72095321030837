import { Button, Text, VStack } from "@chakra-ui/react";

import { useGetCountriesQuery } from "../../../geography/api/geographyApi";
import { useGetEnergyTypesQuery } from "../../api/projectsApi";
import { WatchType } from "../../pages/ProjectCreate";

type ReviewProps = {
    onProjectSubmit: () => void;
    watch: WatchType;
    files: File[];
    nda: File | null;
    ndaSource: "own" | "template";
};

export const Review = ({ onProjectSubmit, watch, files, nda, ndaSource }: ReviewProps) => {
    const { data: energy } = useGetEnergyTypesQuery();
    const { data: countries } = useGetCountriesQuery();

    const getEnergyName = () => {
        if (!energy) return "";

        return energy.results.find(item => item.id === watch("energy_type"))?.name;
    };

    const getCountryName = () => {
        if (!countries) return "";

        return countries.find(item => item.id === watch("country"))?.name;
    };

    const getNdaName = () => {
        if (ndaSource === "own" && nda) {
            return nda.name;
        }

        if (ndaSource === "template") return "CleanHarbour's NDA template";
        return "Not set";
    };

    const reviewRows = [
        {
            label: "Name",
            value: watch("name")
        },
        {
            label: "Description",
            value: watch("description")
        },
        {
            label: "Energy type",
            value: getEnergyName()
        },
        {
            label: "Status",
            value: watch("status")
        },
        {
            label: "Capacity",
            value: `${watch("capacity")} MW`
        },
        {
            label: "Country",
            value: getCountryName()
        },
        {
            label: "Location",
            value: watch("location")
        },
        {
            label: "Target amount",
            value: new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD"
            }).format(watch("target_amount"))
        },
        {
            label: "Minimum investment",
            value: new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD"
            }).format(watch("min_investment"))
        },
        {
            label: "Yield",
            value: `${watch("yield_to_maturity")} %`
        },
        {
            label: "Development deadline",
            value: watch("deadline_years") + " years"
        },
        { label: "Privacy", value: watch("is_private") ? "Enabled" : "Disabled" },
        { label: "NDA", value: getNdaName() }
    ];
    return (
        <VStack spacing='.5rem' alignItems='flex-start'>
            {reviewRows.map(row => (
                <VStack key={row.label} spacing='0.2rem' alignItems='flex-start'>
                    <Text fontWeight='bold'>{row.label}</Text>
                    <Text>{row.value}</Text>
                </VStack>
            ))}
            <VStack spacing='0.2rem' alignItems='flex-start'>
                <Text fontWeight='bold'>Documents - {files.length} uploaded</Text>
                {files.map(file => (
                    <Text key={file.name}>- {file.name}</Text>
                ))}
            </VStack>

            <Button type='submit' onClick={onProjectSubmit} w='full' mt='2rem'>
                Submit
            </Button>
        </VStack>
    );
};
