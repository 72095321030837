import { IconType } from ".";

export const CheckIcon = ({ size = 24, color = "#03C988" }: IconType) => (
    <svg width={size} height={size} viewBox='0 0 24 24' fill='none'>
        <g clipPath='url(#clip0_93_2030)'>
            <path
                d='M9.75 13.8215L18.9694 4.71935C19.039 4.64962 19.1217 4.5943 19.2128 4.55656C19.3038 4.51882 19.4014 4.49939 19.5 4.49939C19.5986 4.49939 19.6962 4.51882 19.7872 4.55656C19.8783 4.5943 19.961 4.64962 20.0306 4.71935L22.2806 6.93185C22.3504 7.00151 22.4057 7.08423 22.4434 7.17527C22.4812 7.26632 22.5006 7.36392 22.5006 7.46248C22.5006 7.56104 22.4812 7.65864 22.4434 7.74968C22.4057 7.84073 22.3504 7.92345 22.2806 7.9931L10.2431 20.0306C10.1735 20.1003 10.0908 20.1557 9.9997 20.1934C9.90866 20.2311 9.81106 20.2506 9.7125 20.2506C9.61394 20.2506 9.51634 20.2311 9.42529 20.1934C9.33424 20.1557 9.25153 20.1003 9.18187 20.0306L2.46937 13.2806C2.32883 13.14 2.24988 12.9493 2.24988 12.7504C2.24988 12.5516 2.32883 12.3609 2.46937 12.2203L4.71937 9.97029C4.78903 9.90056 4.87175 9.84524 4.96279 9.8075C5.05384 9.76975 5.15144 9.75033 5.25 9.75033C5.34856 9.75033 5.44616 9.76975 5.5372 9.8075C5.62825 9.84524 5.71097 9.90056 5.78062 9.97029L9.75 13.8215Z'
                stroke={color}
                strokeWidth={1.5}
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </g>
        <defs>
            <clipPath id='clip0_93_2030'>
                <rect width={24} height={24} fill='white' />
            </clipPath>
        </defs>
    </svg>
);
