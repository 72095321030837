import { Box, Text, VStack } from "@chakra-ui/react";
import { Link } from "react-router-dom";

import { PortfolioType } from "../api/types";
import { PortfolioImage } from "./PortfolioImage";

type PortfolioCardProps = {
    portfolio: PortfolioType;
};

export const PortfolioCard = ({ portfolio }: PortfolioCardProps) => {
    return (
        <Link to={"/portfolio/" + portfolio.id}>
            <Box p='2' borderRadius='lg' boxShadow='lg' h='full'>
                <PortfolioImage images={portfolio.energy_images} />
                <VStack p='1' alignItems='flex-start'>
                    <Text fontWeight='bold'>{portfolio.name}</Text>

                    <Text noOfLines={4} fontSize='xs'>
                        {portfolio.description}
                    </Text>
                </VStack>
            </Box>
        </Link>
    );
};
