import { Heading, Spinner, VStack } from "@chakra-ui/react";

import { GridCards } from "../../../components/GridCards";
import { useGetAcceptedNbosQuery, useGetPendingNbosQuery } from "../../projects/api/projectsApi";
import { NBOCard } from "./NBOCard";

export const NBOList = () => {
    const { data: acceptedNbos, isLoading: loadingAccepted } = useGetAcceptedNbosQuery();
    const { data: pendingNbos, isLoading: loadingPending } = useGetPendingNbosQuery();

    if (loadingAccepted || loadingPending) {
        return <Spinner />;
    }
    return (
        <VStack alignItems='flex-start' spacing='2rem' bg='white' borderRadius='lg' p='1rem'>
            <VStack alignItems='flex-start' w='full'>
                <Heading size='md'>Accepted NBO's</Heading>
                <GridCards emptyString="No accepted NBO's" isEmpty={acceptedNbos?.results.length === 0}>
                    {acceptedNbos?.results.map(nbo => <NBOCard key={nbo.id} nbo={nbo} />)}
                </GridCards>
            </VStack>
            <VStack alignItems='flex-start' w='full'>
                <Heading size='md'>Pending NBO's</Heading>
                <GridCards isEmpty={pendingNbos?.results.length === 0} emptyString="No pending NBO's">
                    {pendingNbos?.results.map(nbo => <NBOCard key={nbo.id} nbo={nbo} />)}
                </GridCards>
            </VStack>
        </VStack>
    );
};
