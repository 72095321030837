import { IconType } from "../icons";

export const SunIcon = ({ size = 24, color = "#03C988" }: IconType) => (
    <svg width={size} height={size} fill='none'>
        <g stroke={color} strokeLinecap='round' strokeLinejoin='round' strokeWidth={1.5} clipPath='url(#a)'>
            <path d='M12 3.75V1.5M12 17.25a5.25 5.25 0 1 0 0-10.5 5.25 5.25 0 0 0 0 10.5ZM6 6 4.5 4.5M6 18l-1.5 1.5M18 6l1.5-1.5M18 18l1.5 1.5M3.75 12H1.5M12 20.25v2.25M20.25 12h2.25' />
        </g>
        <defs>
            <clipPath id='a'>
                <path fill='#fff' d='M0 0h24v24H0z' />
            </clipPath>
        </defs>
    </svg>
);
