import { CloseIcon } from "@chakra-ui/icons";
import { Alert, AlertIcon, Box, Button, FormLabel, HStack, Radio, RadioGroup, Switch, Text, VStack } from "@chakra-ui/react";

import { UploadDoc } from "../../../../components/UploadDoc";
import { SetValueType } from "../../pages/ProjectCreate";

type PrivacyProps = {
    setCurrentIndex: (index: number) => void;
    isPrivate: boolean;
    setValue: SetValueType;
    nda: File | null;
    setNda: React.Dispatch<React.SetStateAction<File | null>>;
    ndaSource: "own" | "template";
    setNdaSource: React.Dispatch<React.SetStateAction<"own" | "template">>;
};

export const Privacy = ({ setCurrentIndex, isPrivate, setValue, nda, setNda, setNdaSource, ndaSource }: PrivacyProps) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            setNda(file);
        }
    };
    return (
        <VStack gap='6'>
            <Alert status='info' borderRadius='lg'>
                <AlertIcon />
                For private projects only users who sign a Non-Disclosure Agreement (NDA) will be able to access your information, ensuring
                confidentiality and protection.
            </Alert>
            <HStack alignItems='center' w='full'>
                <FormLabel htmlFor='edit-nbo' mb='0' mr='2' fontSize='sm'>
                    I want to make this project private
                </FormLabel>
                <Switch id='is-private' isChecked={isPrivate} onChange={() => setValue("is_private", !isPrivate)} />
            </HStack>
            {isPrivate && (
                <VStack gap='1rem' w='full' alignItems='flex-start'>
                    <RadioGroup value={ndaSource} onChange={val => setNdaSource(val as "own" | "template")}>
                        <HStack gap='1rem' w='full' justifyContent='flex-start'>
                            <Radio value='own'>
                                <Text>I'll provide my own NDA document</Text>
                            </Radio>
                            <Radio value='template'>
                                <Text>I want to use CleanHarbour's NDA template</Text>
                            </Radio>
                        </HStack>
                    </RadioGroup>
                    {ndaSource === "own" && (
                        <VStack w='full'>
                            <UploadDoc handleChange={handleChange} />
                            {nda && (
                                <Box w='full' p='1rem' borderWidth='1px' borderRadius='lg'>
                                    <HStack alignItems='center' justifyContent='space-between'>
                                        <HStack alignItems='center'>
                                            <Text>{nda.name}</Text>
                                        </HStack>
                                        <Box
                                            p='2'
                                            bg='red.100'
                                            display='flex'
                                            alignItems='center'
                                            justifyContent='center'
                                            cursor='pointer'
                                            borderRadius='full'
                                            onClick={() => setNda(null)}>
                                            <CloseIcon boxSize='.5rem' color='red.500' />
                                        </Box>
                                    </HStack>
                                </Box>
                            )}
                        </VStack>
                    )}
                </VStack>
            )}
            <Button w='full' onClick={() => setCurrentIndex(6)}>
                Next
            </Button>
        </VStack>
    );
};

