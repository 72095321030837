import { CategoryScale, ChartData, Chart as ChartJS, ChartOptions, Legend, LineElement, LinearScale, PointElement, Title, Tooltip } from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

type LineChartProps = {
    data: ChartData<"line">;
    labelPosition?: "top" | "bottom";
};

export const LineChart = ({ data, labelPosition = "top" }: LineChartProps) => {
    const options: ChartOptions<"line"> = {
        responsive: true,
        // aspectRatio: 1,
        elements: {
            point: {
                radius: 2,
                borderWidth: 1
            }
        },
        scales: {
            x: {
                grid: {
                    display: false
                }
            },
            y: {
                grid: {
                    display: true
                }
            }
        },
        plugins: {
            legend: {
                position: labelPosition,
                display: true,
                onClick: () => null,
                labels: {
                    boxHeight: 1
                }
            },
            title: {
                display: false,
                text: "Chart.js Line Chart"
            }
        }
    };
    return <Line data={data} options={options} />;
};
