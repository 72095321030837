import { type PayloadAction, createSlice } from "@reduxjs/toolkit";

import { type RootState } from "../../../store/state";
import { MessageType } from "../api/types";

interface AuthState {
    goToDialog: number | null;
    currentUser: number | null;
    currentUserName: string;
    messages: MessageType[];
    isLoading: boolean;
    nextPage: number | null;
    isTyping: boolean;
}

export const initialState: AuthState = {
    currentUser: null,
    currentUserName: "",
    messages: [],
    nextPage: null,
    isLoading: true,
    isTyping: false,
    goToDialog: null
};

const chatSlice = createSlice({
    name: "chat",
    initialState,
    reducers: {
        setMessages: (state, action: PayloadAction<MessageType[]>) => {
            state.messages = [...state.messages, ...action.payload];
            state.isLoading = false;
        },
        setNewMessage: (state, action: PayloadAction<MessageType>) => {
            state.messages = [action.payload, ...state.messages];
        },
        setIsTyping: (state, action: PayloadAction<boolean>) => {
            state.isTyping = action.payload;
        },
        setNextPage: (state, action: PayloadAction<number | null>) => {
            state.nextPage = action.payload;
        },
        setChatUser: (state, action: PayloadAction<number | null>) => {
            state.currentUser = action.payload;
        },
        setCurrentUserName: (state, action: PayloadAction<string>) => {
            state.currentUserName = action.payload;
        },
        setGoToDialog: (state, action: PayloadAction<number | null>) => {
            state.goToDialog = action.payload;
        },
        setMessageNboStatus: (
            state,
            action: PayloadAction<{
                nboId: number;
                status: "accepted" | "rejected" | "pending";
            }>
        ) => {
            const newMessages = [...state.messages].map(message => {
                if (message.nbo && message.nbo.id === action.payload.nboId) {
                    message.nbo.status = action.payload.status;
                }
                return message;
            });

            state.messages = newMessages;
        },
        resetMessages: state => {
            state.messages = [];
            state.currentUser = null;
        }
    }
});

// Actions generated from the slice
export const {
    setGoToDialog,
    setMessages,
    resetMessages,
    setChatUser,
    setIsTyping,
    setNewMessage,
    setNextPage,
    setCurrentUserName,
    setMessageNboStatus
} = chatSlice.actions;

// export user selector to get the slice in any component
export const chatSelector = (state: RootState) => state.chat;
// export The reducer
const chatReducer = chatSlice.reducer;
export default chatReducer;
