import { IconType } from ".";

export const FolderIcon = ({ size = 24, color = "black" }: IconType) => (
    <svg width={size} height={size} fill='none' viewBox='0 0 24 24'>
        <path
            stroke={color}
            strokeLinejoin='round'
            strokeWidth={2}
            d='M3 10c0-.932 0-1.398.152-1.765a2 2 0 0 1 1.083-1.083C4.602 7 5.068 7 6 7h12c.932 0 1.398 0 1.765.152a2 2 0 0 1 1.083 1.083C21 8.602 21 9.068 21 10v7c0 1.886 0 2.828-.586 3.414C19.828 21 18.886 21 17 21H7c-1.886 0-2.828 0-3.414-.586C3 19.828 3 18.886 3 17v-7ZM8 7V5a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2'
        />
        <path
            stroke={color}
            strokeLinecap='round'
            strokeWidth={2}
            d='M3 10a7.428 7.428 0 0 0 1.556 3.571C6.195 15.644 8.918 17 12 17s5.805-1.356 7.444-3.429A7.428 7.428 0 0 0 21 10'
        />
        <path stroke={color} strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M11.5 13h1' />
    </svg>
);
