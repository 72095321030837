import { api } from "../../../api/api";
import { DialogsResponse } from "./types";

export const DIALOGS_LIST_TAG = "ListDialogs";

export const chatApi = api.enhanceEndpoints({ addTagTypes: [DIALOGS_LIST_TAG] }).injectEndpoints({
    endpoints: build => ({
        getDialogs: build.query<DialogsResponse, void>({
            query: () => ({
                method: "GET",
                url: "chat/dialogs/",
                keepUnusedDataFor: 120
            }),
            providesTags: [DIALOGS_LIST_TAG]
        })
    })
});

export const { useGetDialogsQuery } = chatApi;
