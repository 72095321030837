import { CloseIcon } from "@chakra-ui/icons";
import { Alert, AlertIcon, Box, Button, HStack, Input, ListItem, Text, UnorderedList, VStack, useDisclosure } from "@chakra-ui/react";
import { useState } from "react";

import { CustomModal } from "../../../../components/CustomModal";
import { UploadDoc } from "../../../../components/UploadDoc";

type DocumentsProps = {
    setCurrentIndex: (index: number) => void;
    files: File[];
    setFiles: React.Dispatch<React.SetStateAction<File[]>>;
};

// https://dribbble.com/shots/18890140-Upload-file-modal-Untitled-UI
export const Documents = ({ setCurrentIndex, files, setFiles }: DocumentsProps) => {
    // Create a reference to the hidden file input element
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [editFileIndex, setEditFileIndex] = useState<{
        index: number;
        name: string;
    }>({ index: 0, name: "" });

    const handleChange: React.ChangeEventHandler<HTMLInputElement> = event => {
        const filesUploaded = event.target.files;

        if (filesUploaded) {
            setFiles(prev => [...prev, ...Array.from(filesUploaded).map(file => file)]);
        }
    };

    const deleteFile = (index: number) => {
        setFiles(prev => prev.filter((_, i) => i !== index));
    };

    const onEditFileName = (index: number) => {
        setEditFileIndex({ index, name: files[index].name });
        onOpen();
    };

    const onEditFileNameConfirm = () => {
        setFiles(prev => {
            const newFiles = [...prev];
            const doc = { ...newFiles[editFileIndex.index], name: editFileIndex.name };
            newFiles[editFileIndex.index] = new File([doc], doc.name);
            return newFiles;
        });
        onClose();
    };

    return (
        <VStack spacing='1rem'>
            <Alert status='info' borderRadius='lg' alignItems='flex-start'>
                <AlertIcon mt='2' />
                <VStack w='full' alignItems='flex-start'>
                    <Text>Preliminary documentation you are willing to reveal upon signing an NDA.</Text>
                    <Text>CleanHarbour’s suggestions:</Text>
                    <UnorderedList>
                        <ListItem>Project Teaser</ListItem>
                        <ListItem>Prospectus</ListItem>
                        <ListItem>Preliminary Financial Model</ListItem>
                        <ListItem>Site Assessment</ListItem>
                        <ListItem>PPA Outline (if applicable)</ListItem>
                        <ListItem>Development Roadmap</ListItem>
                        <ListItem>Risk Assessment</ListItem>
                        <ListItem>Team Overview</ListItem>
                        <ListItem>Other Documents you may find relevant</ListItem>
                    </UnorderedList>
                </VStack>
            </Alert>
            <Alert status='info' borderRadius='lg' alignItems='flex-start'>
                <AlertIcon mt='2' />
                <VStack w='full' alignItems='flex-start'>
                    <Text>Why do we ask for these documents?</Text>
                    <VStack>
                        <Text>
                            <Box fontWeight='bold' display='inline'>
                                Transparency:
                            </Box>{" "}
                            Early-Stage investors need to know if they are getting a solid investment opportunity without compromising your
                            competitive position.
                        </Text>
                        <Text>
                            <Box fontWeight='bold' display='inline'>
                                Credibility:
                            </Box>{" "}
                            Professionally prepared documents signal that the Project is serious, well organized, and has thought through the
                            investment requirements.
                        </Text>
                        <Text>
                            <Box fontWeight='bold' display='inline'>
                                Interest generation:
                            </Box>{" "}
                            A good mix of high level summaries with enough data points helps to generate serious interest without giving away the full
                            details.
                        </Text>
                    </VStack>
                </VStack>
            </Alert>
            <UploadDoc handleChange={handleChange} isMultiple />

            {files.map((file, index) => (
                <Box key={file.name} w='full' p='1rem' borderWidth='1px' borderRadius='lg'>
                    <HStack alignItems='center' justifyContent='space-between'>
                        <HStack alignItems='center'>
                            <Text>{file.name}</Text>
                            <Button size='xs' variant='link' onClick={() => onEditFileName(index)}>
                                Edit name
                            </Button>
                        </HStack>
                        <Box
                            p='2'
                            bg='red.100'
                            display='flex'
                            alignItems='center'
                            justifyContent='center'
                            cursor='pointer'
                            borderRadius='full'
                            onClick={() => deleteFile(index)}>
                            <CloseIcon boxSize='.5rem' color='red.500' />
                        </Box>
                    </HStack>
                </Box>
            ))}

            <Button w='full' mt='2rem' onClick={() => setCurrentIndex(5)}>
                Next
            </Button>
            <CustomModal isOpen={isOpen} onClose={onClose} title='Edit file name' onConfirm={onEditFileNameConfirm}>
                <Input
                    placeholder='File name'
                    value={editFileIndex.name}
                    onChange={e => {
                        setEditFileIndex({ ...editFileIndex, name: e.target.value });
                    }}
                />
            </CustomModal>
        </VStack>
    );
};
