import { api } from "../../../api/api";
import { LoginResult, UserRegisterData } from "./types";

const GEO_TAG = "geography";

export const authApi = api.enhanceEndpoints({ addTagTypes: [GEO_TAG] }).injectEndpoints({
    endpoints: build => ({
        loginUser: build.mutation<LoginResult, { email: string; password: string }>({
            query: ({ email, password }) => ({
                method: "POST",
                url: "auth/login/",
                body: { email, password }
            })
        }),
        registerUser: build.mutation<void, UserRegisterData>({
            query: args => ({
                body: { ...args },
                method: "POST",
                url: "/auth/register/"
            })
        }),
        logoutUser: build.mutation<void, void>({
            query: () => ({
                method: "POST",
                url: "/auth/logout/"
            })
        }),
        verifyToken: build.mutation<void, { token: string }>({
            query: args => ({
                body: args,
                method: "POST",
                url: "/auth/token/verify/"
            })
        })
    })
});

export const { useLoginUserMutation, useRegisterUserMutation, useLogoutUserMutation, useVerifyTokenMutation } = authApi;
