import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";

import { CompanyDetails } from "../components/Company";
import { UserDetails } from "../components/Details";
import { PreferencesDetails } from "../components/Preferences";

export const Profile = () => {
    return (
        <Box maxW='30rem' mx='auto'>
            <Tabs variant='soft-rounded' colorScheme='green' size='sm'>
                <TabList position='sticky'>
                    <Tab>Profile</Tab>
                    <Tab>Preferences</Tab>
                    <Tab>Company</Tab>
                </TabList>
                <TabPanels>
                    <TabPanel p='4' bg='white' mt='3' borderRadius='xl' height='60vh'>
                        <UserDetails />
                    </TabPanel>
                    <TabPanel p='4' bg='white' mt='3' borderRadius='xl' height='60vh'>
                        <PreferencesDetails />
                    </TabPanel>
                    <TabPanel p='4' bg='white' mt='3' borderRadius='xl' height='60vh'>
                        <CompanyDetails />
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Box>
    );
};
