import { Box, Button, HStack, Heading, IconButton, Image, Text, VStack } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

import { DeleteIcon } from "../../../assets/icons/delete";
import { EditIcon } from "../../../assets/icons/edit";
import { CalendarIcon } from "../../../assets/icons/projects/calendar";
import { CapacityIcon } from "../../../assets/icons/projects/capacity";
import { TypeIcon } from "../../../assets/icons/projects/type";
import { YieldIcon } from "../../../assets/icons/projects/yield";
import { useEditProjectContext } from "../../../context/EditProjectContext";
import { getCountryFlag } from "../../../utils/flags";
import { authSelector } from "../../auth/data/authSlice";
import { Project } from "../api/types";
import { EnergyBadge } from "./EnergyBadge";
import { StatusBadge } from "./StatusBadge";

type ProjectCardProps = {
    project: Project;
};

export const ProjectCard = ({ project }: ProjectCardProps) => {
    const { portfolioId } = useParams<{ portfolioId: string }>();
    const isPortfolio = Boolean(project.portfolio);
    const isSamePortfolio = project.portfolio === Number(portfolioId);
    const { onDeletePress, onEditPress } = useEditProjectContext();
    const { userId } = useSelector(authSelector);

    const isEditEnabled = project.creator.id === userId;

    return (
        <Box borderWidth='1px' borderColor='gray.200' borderRadius='md' w='full' shadow='lg' display='flex' flexDirection='column' h='23rem'>
            <Box position='relative' h='10rem'>
                <Box position='absolute' left='0' right='0' top='0' bottom='0' bg='rgba(0,0,0,0.3)' borderRadius='md' />
                <Box position='absolute' top='.5rem' right='.5rem'>
                    <Image w='1.8rem' src={getCountryFlag(project.location.country.code)} alt={project.energy_type.name} borderRadius='lg' />
                </Box>
                {isEditEnabled && (
                    <Box position='absolute' bottom='.5rem' right='.5rem'>
                        <HStack spacing='2'>
                            <IconButton
                                aria-label='edit-project'
                                icon={<DeleteIcon size={18} color='red' />}
                                colorScheme='none'
                                onClick={() => onDeletePress(project)}
                                bg='rgba(255,255,255,0.8)'
                                size='xs'
                            />
                            <IconButton
                                aria-label='edit-project'
                                icon={<EditIcon size={18} />}
                                colorScheme='none'
                                onClick={() => onEditPress(project)}
                                bg='rgba(255,255,255,0.8)'
                                size='xs'
                            />
                        </HStack>
                    </Box>
                )}

                <Box flexGrow='1' bgImage={`url(${project.energy_type.image})`} bgSize='cover' bgPosition='center' borderTopRadius='md' h='full' />
            </Box>
            <VStack p='2' alignItems='flex-start' spacing='4' justifyContent='space-between' flexGrow='1'>
                <VStack w='full' alignItems='flex-start'>
                    <HStack alignItems='center' justifyContent='space-between' w='full'>
                        <EnergyBadge energyType={project.energy_type.name} />
                        <StatusBadge status={project.status.name} />
                    </HStack>
                    <Heading size='sm'>{project.name}</Heading>

                    <VStack spacing='1' alignItems='flex-start'>
                        <InfoRow label='Type' value={project.energy_type.name} icon={<TypeIcon />} />
                        {project?.capacity && <InfoRow label='Capacity' value={`${project.capacity} MW`} icon={<CapacityIcon />} />}
                        {project?.yield_to_maturity && <InfoRow label='Yield' value={`${project.yield_to_maturity} %`} icon={<YieldIcon />} />}
                        <InfoRow label='Development maturity' value={`${project.deadline_years} years`} icon={<CalendarIcon />} />
                    </VStack>
                </VStack>
                <HStack spacing='.5rem' w='full'>
                    <Button as={Link} to={"/projects/" + project.slug} alignSelf='center' size='sm' flexGrow={1}>
                        View project
                    </Button>
                    {isPortfolio && !isSamePortfolio && (
                        <Button as={Link} to={"/portfolio/" + String(project.portfolio)} alignSelf='center' size='sm' variant='outline' flexGrow={1}>
                            View portfolio
                        </Button>
                    )}
                </HStack>
            </VStack>
        </Box>
    );
};

const InfoRow = ({ label, value, icon }: { label: string; value: string; icon: JSX.Element }) => {
    return (
        <HStack spacing='1'>
            <HStack spacing='1' alignItems='center'>
                {icon}
                <Text fontWeight='bold' fontSize='sm'>
                    {label}:
                </Text>
            </HStack>
            <Text fontSize='sm'>{value}</Text>
        </HStack>
    );
};
