export const TypeIcon = () => (
    <svg width={17} height={17} fill='none'>
        <path
            fill='#2F6F2F'
            d='M8.557 3.227a3.785 3.785 0 0 0-3.545 3.66c-.002.817.27 1.611.77 2.256a7.372 7.372 0 0 1 1.334 2.68v.865a.935.935 0 0 0 .936.936h1.302a.935.935 0 0 0 .937-.936v-.829h.007a7.698 7.698 0 0 1 1.319-2.774c.48-.632.74-1.403.742-2.197a3.697 3.697 0 0 0-3.802-3.66Zm-.584 7.637L8.37 8.3H6.986L9.5 4.71l-.428 2.566h1.422l-2.522 3.588Z'
        />
        <path
            fill='#2F6F2F'
            d='M1.443 8.47a7.038 7.038 0 0 1 1.24-4.005L4.09 5.872l.407-4.187-4.162.432 1.322 1.32-.072.075A8.53 8.53 0 0 0 8.53 16.996v-1.438A7.097 7.097 0 0 1 1.443 8.47ZM15.413 13.491A8.531 8.531 0 0 0 8.467.004v1.441a7.086 7.086 0 0 1 5.845 11.093l-1.407-1.407-.406 4.188 4.16-.433-1.317-1.319.071-.076Z'
        />
    </svg>
);
