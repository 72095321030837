import {
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    VStack
} from "@chakra-ui/react";
import { useEffect } from "react";

import { useEnergyTypeSelect } from "../../../../hooks/useEnergyTypeSelect";
import { useStatusSelect } from "../../../../hooks/useStatusSelect";
import { ClearErrorsType, ErrorsType, SetErrorType, SetValueType, UnregisterType } from "../../pages/ProjectCreate";

type DetailsProps = {
    setCurrentIndex: (index: number) => void;
    setValue: SetValueType;
    setError: SetErrorType;
    errors: ErrorsType;
    clearErrors: ClearErrorsType;
    energy: number;
    status: number;
    capacity: number;
    unregister: UnregisterType;
};

export const Details = ({ setCurrentIndex, errors, setValue, status, energy, capacity, clearErrors, setError, unregister }: DetailsProps) => {
    const { EnergyTypeSelect, value: energyTypeId } = useEnergyTypeSelect(energy);
    const { ProjectStatusSelect, value: statusValue } = useStatusSelect(status, "Choose the current stage");

    useEffect(() => {
        if (energyTypeId) {
            setValue("energy_type", energyTypeId);
        } else {
            unregister("energy_type");
        }
    }, [energyTypeId, setValue, unregister]);

    useEffect(() => {
        if (statusValue) {
            setValue("status", statusValue);
        }
    }, [statusValue, setValue]);

    const onValidate = () => {
        clearErrors();
        if (!energy) {
            setError("energy_type", { message: "Energy type is required" });
            return;
        }
        if (!status) {
            setError("status", { message: "Status is required" });
            return;
        }
        if (!capacity) {
            setError("capacity", { message: "Capacity is required" });
            return;
        }
        setCurrentIndex(2);
    };

    return (
        <VStack spacing='1rem'>
            <FormControl isInvalid={Boolean(errors.energy_type)}>
                <FormLabel htmlFor='energy_type'>Energy type</FormLabel>
                {EnergyTypeSelect}
                <FormErrorMessage>{errors.energy_type && errors.energy_type.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={Boolean(errors.status)}>
                <FormLabel htmlFor='status'>Project stage</FormLabel>
                {ProjectStatusSelect}
                <FormErrorMessage>{errors.status && errors.status.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={Boolean(errors.capacity)}>
                <FormLabel htmlFor='capacity'>Capacity</FormLabel>
                <NumberInput step={5} value={capacity}>
                    <NumberInputField id='capacity' onChange={e => setValue("capacity", Number(e.target.value))} />
                    <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                    </NumberInputStepper>
                </NumberInput>
                <FormErrorMessage>{errors.capacity && errors.capacity.message}</FormErrorMessage>
            </FormControl>
            <Button type='submit' onClick={onValidate} w='full' mt='2rem'>
                Next
            </Button>
        </VStack>
    );
};
