import { Text, VStack } from "@chakra-ui/react";
import { Link } from "react-router-dom";

export const Footer = () => {
    return (
        <VStack p='8' bg='green.400' color='white' fontSize='sm' w='full' justifyContent='center'>
            <Text>© {new Date().getFullYear()} CleanHarbour. All rights reserved.</Text>
            <Link to='/terms-conditions'>Terms & Conditions</Link>
        </VStack>
    );
};
