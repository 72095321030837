const { REACT_APP_API_ENDPOINT_DEV, REACT_APP_API_ENDPOINT, REACT_APP_PRODUCTION } = process.env;

const isDevEnv = !Number(REACT_APP_PRODUCTION);

export const config = {
    appName: "CleanHarbour",
    isDevEnv,
    apiEndpoint: isDevEnv ? REACT_APP_API_ENDPOINT_DEV : REACT_APP_API_ENDPOINT,
    supportEmail: "io@cleanharbour.io"
};
