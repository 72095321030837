import { Box, Text, TypographyProps } from "@chakra-ui/react";

import { EnergyTypeText } from "../api/types";

export const EnergyBadge = ({ energyType, fontSize = "xs" }: { energyType: EnergyTypeText; fontSize?: TypographyProps["fontSize"] }) => {
    const color: Record<EnergyTypeText, { bg: string; border: string }> = {
        Bio: { bg: "green.200", border: "green.700" },
        Geothermal: { bg: "orange.200", border: "orange.700" },
        Hydropower: { bg: "blue.200", border: "blue.700" },
        BESS: { bg: "cyan.200", border: "cyan.700" },
        Solar: { bg: "yellow.200", border: "yellow.700" },
        Wind: { bg: "purple.200", border: "purple.700" }
    };
    const { bg, border } = color[energyType];
    return (
        <Box py='.5' px='2' borderRadius='xl' bg={bg} borderWidth='1px' borderColor={border}>
            <Text fontSize={fontSize} color={border}>
                {energyType}
            </Text>
        </Box>
    );
};
