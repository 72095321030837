import { api } from "../../../api/api";
import { DashboardType } from "./types";

const DASHBOARD = "Dashboard";

export const dashboardApi = api.enhanceEndpoints({ addTagTypes: [DASHBOARD] }).injectEndpoints({
    endpoints: build => ({
        getDashboardDetails: build.query<DashboardType, void>({
            query: () => ({
                method: "GET",
                url: "dashboard"
            }),
            providesTags: [DASHBOARD]
        })
    })
});

export const { useGetDashboardDetailsQuery } = dashboardApi;
